import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import SameDateModal from "../Booking-Optimization/Modal/sameDateModal";
import Select from "react-select";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import {
	bookingActions,
	kitActions,
	setActions,
	operationActions,
} from "../../../../actions";
import dateFormat from "dateformat";
// import DatePicker from "react-datepicker";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import AddSurgeon from "../Surgeon/Add";
import AddHospital from "../Hospitals/Add/Index";
import UpdateHospital from "../Hospitals/Update/Index";
import DatePicker from "react-datepicker";
import { bookingService } from "../../../../services/booking.services";
import { surgeonService } from "../../../../services/surgeon.services";
import KitModal from "../Kits/loanCharge";
import { loanChargeService } from "../../../../services/loanCharge.service";
import { history } from "../../../../helpers/";
import { operationService } from "../../../../services/operation.services";
import WarningModal from "./Warning";

import DoubleBookingDetails from "./DoubleBookingDetails";

const deliverySlots = [
	{ value: "Pre 9AM", label: "Pre 9AM" },
	{ value: "Pre 10AM", label: "Pre 10AM" },
	{ value: "Pre 12", label: "Pre 12" },
	{ value: "Same Day", label: "Same Day" },
];

const operationSlots = [
	{ value: "AM", label: "AM" },
	{ value: "PM", label: "PM" },
	{ value: "TBD", label: "TBD" },
];

const bilateralCaseList = [
	{ value: "TBC", label: "TBC" },
	{ value: "YES", label: "YES" },
	{ value: "NO", label: "NO" },
];

const dayName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

class Edit extends Component {
	constructor(props) {
		super(props);
		// reset login status
		this.state = {
			user: JSON.parse(localStorage.getItem("user")),
			kitSetErrorFlag: 0,
			updateBookingInfo: {
				id: "",
				holidays: [],
				booking_type: "",
				hospitalId: "",
				hospital_address: "",
				hospital_department: "",
				po_number: "",
				operation_name: "",
				shipping_charge: "",
				additional_notes: "",
				assigned_rep: "",
				additional_reps: [],
				delivery_date: new Date("2022-05-11"),
				delivery_date_slot: "11 am",
				operation_date: new Date("2022-05-11"),
				operation_date_slot: "12 pm",
				collection_date: new Date("2022-05-14"),
				return_date: "",
				reject_status: "",
				kitData: [],
				kitSetData: [],
				log_status: "",
				log_by_id: "",
				surgeonData: [],
				other_opration: "",
				mail_sent: false,
				po_check: false,
				isOpen: false,
				pickup_location_checkbox: false,
				pickup_location_text: "",
				bilateral_case: "",
			},
			sameDatesModal: false,
			sameDatesInfo: [],
			holidays: [],
			bookingInfo: {},
			previousSelectedHospital: {},
			selectedOperation: {},
			selectedHospital: {},
			selectedDeliverySlot: [],
			selectedOperationSlots: [],
			selectedRep: [],
			selectedAdditionalRep: [],
			reps: [],
			additionalReps: [],
			hospitals: [],
			hospitalDetails: [],
			repsDetails: [],
			hospitalAddress: [],
			hospitalContactPerson: "",
			hospitalContactPersonEmail: "",
			hospitalContactPersonPhone: "",
			searchHospital: "",
			searchReps: "",
			searchOps: "",
			kits: [],
			kitListFlag: 0,
			selectedKit: [],
			kitWithKitSet: [],
			kitSetDetailModal: false,
			kitDetails: [],
			kitDetailsClickFlag: 0,
			bookingType: [
				{ value: "loan", label: "Loan" },
				{ value: "consignment", label: "Consignment" },
			],
			selectedBookingType: [],
			selectedSurgeon: [],
			surgeonList: [],
			operationsList: [],
			addSurgeonModal: false,
			addNewHospitalModalProp: false,
			singleSelectedKitId: 0,
			Allkits: [],
			cartFlag: false,
			cartItemCount: 0,
			addedKitFlag: false,
			editHospitalModal: false,
			editHospitalId: 0,
			hospitalDepartment: [],
			surgeonNoteDetails: "",
			suggestedOperationSets: [],
			selectedSetInfo: [],
			isLoading: false,
			doubleBookingDetailModal: false,
			doubleBookingInfo: [],
			selectedBilateralCase: [],
			isEmailSend: false,
		};

		this.getList = this.getList.bind(this);
		this.getSingleBooking = this.getSingleBooking.bind(this);
		this.getHospitalAddressList = this.getHospitalAddressList.bind(this);
		this.showHospitalsList = this.showHospitalsList.bind(this);
		this.showOperationsList = this.showOperationsList.bind(this);
		this.handleUpdateBookingInfo = this.handleUpdateBookingInfo.bind(this);
		this.setSelectedOptionOperation =
			this.setSelectedOptionOperation.bind(this);
		this.setSelectedOptionHospital =
			this.setSelectedOptionHospital.bind(this);
		this.setSelectedOptionDeliverySlot =
			this.setSelectedOptionDeliverySlot.bind(this);
		this.setSelectedOptionOperationSlots =
			this.setSelectedOptionOperationSlots.bind(this);
		this.setSelectedOptionRep = this.setSelectedOptionRep.bind(this);
		this.setSelectedOptionAdditionalRep =
			this.setSelectedOptionAdditionalRep.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleBookingAddress = this.handleBookingAddress.bind(this);
		this.handleBookingDepartment = this.handleBookingDepartment.bind(this);
		this.setSelectedOptionKit = this.setSelectedOptionKit.bind(this);
		this.handleAddKit = this.handleAddKit.bind(this);
		this.handelAddRemovSet = this.handelAddRemovSet.bind(this);
		this.handleHireCharge = this.handleHireCharge.bind(this);
		this.handleKitDetails = this.handleKitDetails.bind(this);
		this.showKitDetails = this.showKitDetails.bind(this);
		this.kitSetDetailModalClose = this.kitSetDetailModalClose.bind(this);
		this.setSelectedOptionBookingType =
			this.setSelectedOptionBookingType.bind(this);
		this.setSelectedOptionSurgeon =
			this.setSelectedOptionSurgeon.bind(this);
		this.addSurgeonModal = this.addSurgeonModal.bind(this);
		this.addNewSurgeonModalClose = this.addNewSurgeonModalClose.bind(this);

		this.editHospitalModalClose = this.editHospitalModalClose.bind(this);
		this.refreshHospitalList = this.refreshHospitalList.bind(this);
		this.setSugestedSelectedOprationSet =
			this.setSugestedSelectedOprationSet.bind(this);
		this.doubleBookingDetailModalClose =
			this.doubleBookingDetailModalClose.bind(this);
		this.setSelectedOptionBilateralCase =
			this.setSelectedOptionBilateralCase.bind(this);
	}

	openModal = () => {
		this.setState({ isOpen: true });
	};

	closeModal = () => {
		this.setState({ isOpen: false });
	};

	getSingleBooking(id) {
		this.props.dispatch(bookingActions.getById(id));
	}

	getList() {
		this.props.dispatch(
			bookingActions.getRepsList({ search: this.state.searchReps })
		);
		this.props.dispatch(kitActions.kitListDropdown({ search: "" }));
		this.props.dispatch(
			operationActions.getOperationsList({
				search: this.state.searchOps,
			})
		);
	}

	cartToggle() {
		this.setState({
			cartFlag: !this.state.cartFlag,
		});
	}

	getHospitalAddressList() {
		if (this.state.selectedHospital.length > 0) {
			bookingService
				.getHospitalDetailsById(this.state.selectedHospital[0].value)
				.then((response) => {
					this.showAddressList(response.data);
				});
		}
	}

	setHospitalEdit() {
		const { updateBookingInfo } = this.state;
		var defaultHospital = this.state.hospitals.find(
			(val) => val.value === updateBookingInfo.hospitalId
		);
		this.setState({
			updateBookingInfo,
			previousSelectedHospital: [defaultHospital],
			selectedHospital: [defaultHospital],
		});
	}

	setBookingData(data) {
		var defaultReps = this.state.reps.find(
			(val) => val.value === data.assigned_rep
		);
		var defaultAdditionalReps = [];
		data.additional_reps.map((repValue) => {
			defaultAdditionalReps.push(
				this.state.additionalReps.find((val) => val.value === repValue)
			);
		});

		var surgeonData = [];
		data?.bookingSurgeonId.map((surVal) => {
			surgeonData.push(surVal.surgeonId[0]._id);
		});

		let selectedOperationName = data["operation_name"];

		operationService
			.getSuggestedSetByOperation({
				operationName: selectedOperationName,
			})
			.then((response) => {
				var operationSuggestedList = response.data;
				if (operationSuggestedList.length > 0) {
					operationSuggestedList.map((item) => {
						data?.bookingKitSetId.map((sets) => {
							if (
								item?.suggestedSetId[0]?._id ==
								sets?.setId[0]?._id
							) {
								item.suggestedSetId[0].operationSuggestedFlag = true;
							}
						});
					});
				}
				this.setState({
					suggestedOperationSets: operationSuggestedList,
				});
			});

		const { updateBookingInfo } = this.state;
		updateBookingInfo["id"] = data["_id"];
		updateBookingInfo["operation_name"] = data["operation_name"];
		updateBookingInfo["hospitalId"] = data["hospitalId"];
		updateBookingInfo["hospital_address"] = data["hospital_address"];
		updateBookingInfo["hospital_department"] = data["hospital_department"];
		updateBookingInfo["operation_date_slot"] = data["operation_date_slot"];
		updateBookingInfo["po_number"] = data["po_number"];
		updateBookingInfo["shipping_charge"] = data["shipping_charge"];
		updateBookingInfo["delivery_date_slot"] = data["delivery_date_slot"];
		updateBookingInfo["assigned_rep"] = data["assigned_rep"];

		updateBookingInfo["delivery_date"] = new Date(data["delivery_date"]);
		updateBookingInfo["operation_date"] = new Date(data["operation_date"]);
		updateBookingInfo["collection_date"] = new Date(
			data["collection_date"]
		);
		updateBookingInfo["additional_notes"] = data["additional_notes"];
		updateBookingInfo["booking_type"] = data["booking_type"];
		updateBookingInfo["status"] = data["status"];
		updateBookingInfo["surgeonData"] = surgeonData;
		updateBookingInfo["other_opration"] = data["other_opration"];
		updateBookingInfo["po_check"] = data["po_check"];
		updateBookingInfo["pickup_location_checkbox"] =
			data["pickup_location_checkbox"];
		updateBookingInfo["pickup_location_text"] =
			data["pickup_location_text"];

		var tempAdditionalReps = [];
		data.additional_reps.map((item) => {
			tempAdditionalReps.push({ id: item });
		});
		updateBookingInfo["additional_reps"] = tempAdditionalReps;
		updateBookingInfo["bilateral_case"] = data["bilateral_case"];

		//additional_reps

		this.setState(
			{
				updateBookingInfo,
				bookingInfo: data,
				selectedOperation: {
					value: data.operation_name,
					label: data.operation_name,
				},
				selectedOperationSlots: {
					value: data.operation_date_slot,
					label: data.operation_date_slot,
				},
				selectedDeliverySlot: {
					value: data.delivery_date_slot,
					label: data.delivery_date_slot,
				},
				selectedRep: defaultReps,
				selectedAdditionalRep: defaultAdditionalReps,
				selectedBookingType: [
					{
						value: data.booking_type,
						label: this.capitalize(data.booking_type),
					},
				],
				selectedHospital: [{ value: data.hospitalId, label: "" }],
				selectedBilateralCase: [
					{ value: data.bilateral_case, label: data.bilateral_case },
				],
			},
			() => {
				this.showHospitalsList();
				this.getHospitalAddressList();
				this.setSurgeonList();
			}
		);

		//console.log("booking dfdsfdsfd fsdf ", data);

		data.bookingKitId.map((item) => {
			this.props.dispatch(
				bookingActions.getKitSetByKit({ kitId: item.kitId })
			);
		});
	}

	showHospitalsList() {
		bookingService
			.getHospitalsList({ search: this.state.searchHospital })
			.then((response) => {
				var hospitalsList = response.data;

				var tempHospitals = [];
				hospitalsList.map((item) => {
					var option = { value: item._id, label: item.name };
					tempHospitals.push(option);
				});
				this.setState(
					{
						hospitalDetails: hospitalsList,
						hospitals: tempHospitals,
					},
					() => {
						this.setHospitalEdit();
					}
				);
			});
	}

	showOperationsList(operationsList) {
		var tempOperations = [];
		operationsList.map((item) => {
			var option = { value: item.name, label: item.name, id: item._id };
			tempOperations.push(option);
		});

		this.setState({
			operationsList: tempOperations,
		});
	}

	showAddressList(address) {
		var hospitalAddress = [];
		var hospitalDepartment = [];
		address.addresses.map((item) => {
			if (this.state.bookingInfo.hospital_address === item.name) {
				hospitalAddress.push({
					_id: item._id,
					name: item.name,
					checked: true,
				});
			} else {
				hospitalAddress.push({
					_id: item._id,
					name: item.name,
					checked: false,
				});
			}
		});

		address.departments.map((item) => {
			if (this.state.bookingInfo.hospital_department === item.name) {
				hospitalDepartment.push({
					_id: item._id,
					name: item.name,
					checked: true,
				});
			} else {
				hospitalDepartment.push({
					_id: item._id,
					name: item.name,
					checked: false,
				});
			}
		});

		this.setState(
			{
				hospitalAddress: hospitalAddress,
				hospitalDepartment: hospitalDepartment,
				hospitalContactPerson: address.contact_person_name,
				hospitalContactPersonEmail: address.email,
				hospitalContactPersonPhone: address.contact_person_phone_number,
			},
			() => {
				console.log(this.state.hospitalAddress);
			}
		);
	}

	showRepsList(repsList) {
		var tempRepsList = [];
		repsList.map((item) => {
			var option = {
				value: item._id,
				label: item.first_name + " " + item.last_name,
			};
			tempRepsList.push(option);
		});
		this.setState({
			repsDetails: repsList,
			reps: tempRepsList,
			additionalReps: tempRepsList,
		});
	}

	setSelectedOptionOperation(e) {
		var selectedOperation = [{ value: e.value, label: e.label }];
		if (e.label != "Other") {
			this.setState({
				selectedOperation: selectedOperation,
				updateBookingInfo: {
					...this.state.updateBookingInfo,
					operation_name: e.label,
					other_opration: "",
				},
				// changesMade: true,
			});
		}
		if (e.label == "Other") {
			this.setState({
				selectedOperation: selectedOperation,
				updateBookingInfo: {
					...this.state.updateBookingInfo,
					operation_name: e.label,
				},
			});
		}

		operationService
			.getSuggestedSetByOperation({ operationName: e.label })
			.then((response) => {
				const { kitWithKitSet } = this.state;
				let operationSuggestedList = response.data;
				if (kitWithKitSet.length && operationSuggestedList.length) {
					kitWithKitSet.map((item) => {
						item.sets.map((subItem) => {
							operationSuggestedList.map((sets) => {
								if (
									sets?.suggestedSetId[0]?._id ==
										subItem?._id &&
									subItem?.added == 1
								) {
									sets.suggestedSetId[0].operationSuggestedFlag = true;
								}
							});
						});
					});
				}
				this.setState({
					suggestedOperationSets: operationSuggestedList,
				});
			});
	}

	setSelectedOptionBookingType(e) {
		var selectedBookingType = [{ value: e.value, label: e.label }];

		this.setState({
			selectedBookingType: selectedBookingType,
			updateBookingInfo: {
				...this.state.updateBookingInfo,
				booking_type: e.value,
			},
		});

		// this.setState({
		//   changesMade: true,
		// });
	}

	setSelectedOptionHospital(e) {
		var selectedHospital = [{ value: e.value, label: e.label }];
		this.setState(
			{
				selectedHospital: selectedHospital,
				updateBookingInfo: {
					...this.state.updateBookingInfo,
					hospitalId: e.value,
				},
				// changesMade: true,
			},
			() => {
				this.getHospitalAddressList();
				this.handleAddKit();
			}
		);
	}

	setSelectedOptionRep(e) {
		var selectedRep = [{ value: e.value, label: e.label }];
		this.setState({
			selectedRep: selectedRep,
			updateBookingInfo: {
				...this.state.updateBookingInfo,
				assigned_rep: e.value,
			},
			// changesMade: true,
		});
	}

	setSelectedOptionAdditionalRep = (selectedAdditionalRep) => {
		var tempAdditionalReps = [];
		selectedAdditionalRep.map((item) => {
			tempAdditionalReps.push({ id: item.value });
		});
		this.setState(
			{
				selectedAdditionalRep: selectedAdditionalRep,
				updateBookingInfo: {
					...this.state.updateBookingInfo,
					additional_reps: tempAdditionalReps,
				},
				// changesMade: true,
			},
			() => {
				console.log(this.state.updateBookingInfo.additional_reps);
			}
		);
	};

	setSelectedOptionKit(e) {
		var selectedKit = [{ value: e.value, label: e.label }];
		if (this.state.updateBookingInfo.status == "pending") {
			var kLength = this.state.kitWithKitSet.filter(
				(k) => k._id == e.value
			).length;
			if (kLength == 0) {
				this.setState({
					addedKitFlag: true,
				});
			} else {
				this.setState({
					addedKitFlag: false,
				});
			}
		}
		this.setState(
			{
				selectedKit: selectedKit,
				cartFlag: false,
				// changesMade: true,
			},
			() => {
				this.handleAddKit();
			}
		);
	}

	showKitsList(kitList) {
		var tempKitList = [];
		kitList.map((item) => {
			var option = { value: item._id, label: item.name };
			tempKitList.push(option);
		});
		this.setState({
			kits: tempKitList,
			Allkits: tempKitList,
			kitListFlag: 1,
		});
	}

	handleAddKit() {
		if (this.state.selectedKit.length > 0) {
			// console.log(this.state.kitWithKitSet.filter(k => k.cartAdded == 0 && (k.hire_charge != "" && k.hire_charge != null)));
			this.setState({
				kitSetErrorFlag: 0,
			});
			this.props.dispatch(
				bookingActions.getKitSetByKit({
					kitId: this.state.selectedKit[0].value,
				})
			);
			this.setState({
				singleSelectedKitId: this.state.selectedKit[0].value,
			});
		}
	}

	async showKitsSetList(data) {
		const {
			kitWithKitSet,
			updateBookingInfo,
			bookingInfo,
			singleSelectedKitId,
		} = this.state;
		var subKitSet = [];
		var lnt = 0;
		var hire_charge = 695;

		if (this.state.selectedHospital.length) {
			await loanChargeService
				.getLoanScoreByHospitalKit({
					kitId: singleSelectedKitId,
					hospitalId: this.state.selectedHospital[0].value,
				})
				.then((res) => {
					if (res.data.length > 0) {
						hire_charge = res.data[0].loan_charge;
					}
				});
		}
		console.log("+++++++++++++++", data[0]);
		data[0]["kitsets"].map((kset) => {
			//hire_charge = 695;
			lnt = data[0].bookingKitSets.filter(
				(rs) =>
					rs.setId === kset._id &&
					rs.bookingId == updateBookingInfo.id
			).length;
			if (lnt > 0) {
				data[0].bookingKitSets
					.filter(
						(rs) =>
							rs.setId === kset._id &&
							rs.bookingId == updateBookingInfo.id
					)
					.map((result) => {
						//hire_charge = result.hire_charge;
						subKitSet.push({
							bookingKitSets: data[0]["bookingKitSets"],
							kitsets: data[0]["kitsets"],
							missing_stock_check: kset.missing_stock_check,
							quarantine_check: kset.quarantine_check,
							set_name: kset.set_name,
							_id: kset._id,
							hire_charge: result.hire_charge,
							added: lnt,
						});
					});
			} else {
				subKitSet.push({
					bookingKitSets: data[0]["bookingKitSets"],
					kitsets: data[0]["kitsets"],
					missing_stock_check: kset.missing_stock_check,
					quarantine_check: kset.quarantine_check,
					set_name: kset.set_name,
					_id: kset._id,
					hire_charge: hire_charge,
					added: lnt,
				});
			}
		});

		var kitquantity = "";
		var kithire_charge = "";

		bookingInfo.bookingKitId
			.filter((item) => item.kitId == data[0]._id)
			.map((maini) => {
				kithire_charge = maini.hire_charge;
				kitquantity = maini.quantity;
			});

		if (kitWithKitSet.filter((item) => item._id == data[0]._id) == 0) {
			var cartAdded = 0;
			if (subKitSet.length) {
				cartAdded = subKitSet.filter((item) => item.added == 1).length;
			}
			console.log("hahahahah", subKitSet);
			kitWithKitSet.push({
				kitDocument: data[0].kitDocument,
				cartAdded: cartAdded,
				name: data[0].name,
				_id: data[0]._id,
				hire_charge: kithire_charge,
				quantity: kitquantity,
				sets: subKitSet,
			});
		} else {
			kitWithKitSet.map(function (kits) {
				if (kits._id == data[0]._id) {
					kits.sets.map(function (subSets) {
						if (subSets.added == 0) {
							subSets.hire_charge = hire_charge;
						}
					});
				}
			});
		}

		if (
			this.state.updateBookingInfo.status == "pending" &&
			this.state.kitWithKitSet.filter(
				(item) =>
					(item.quantity > 0 || item.hire_charge > 0) &&
					item._id == singleSelectedKitId
			).length > 0
		) {
			kitWithKitSet
				.filter((ft) => ft._id == singleSelectedKitId)
				.map((itm) => {
					itm.sets
						.filter((sft) => sft.added == 0)
						.map((st) => {
							st.hire_charge = itm.hire_charge;
						});
				});
		}

		var kits = this.state.Allkits;
		kits = kits.filter((rs) => rs.value !== singleSelectedKitId);

		this.setState(
			{
				kitWithKitSet,
				selectedKit: [],
				kits: kits,
			},
			() => {
				if (this.state.selectedSetInfo.length > 0) {
					this.handelAddRemovSet(
						this.state.selectedSetInfo[0].kitId,
						this.state.selectedSetInfo[0].setId,
						1
					);
					this.setState({ selectedSetInfo: [] });
				} else {
					this.calculateItemCount();
				}
			}
		);
	}

	handelRemovKitFromCart(kiIndex, kit_id) {
		const { kitWithKitSet, suggestedOperationSets } = this.state;
		kitWithKitSet.map((item) => {
			if (item._id == kit_id) {
				item.sets.map((subItem) => {
					subItem.added = 0;
				});
				item.cartAdded = 0;
			}
		});

		if (suggestedOperationSets.length > 0) {
			suggestedOperationSets.map((item) => {
				if (item?.suggestedSetId[0]?.kitID[0]?._id == kit_id) {
					item.suggestedSetId[0].operationSuggestedFlag = false;
				}
			});
		}

		this.setState(
			{
				kitWithKitSet,
				cartFlag: !this.state.cartFlag,
				suggestedOperationSets,
				// changesMade: true,
			},
			() => {
				this.calculateItemCount();
			}
		);
	}

	calculateItemCount() {
		const { kitWithKitSet } = this.state;
		let cartItemCount = 0;

		cartItemCount = kitWithKitSet
			.map((datum) => datum.cartAdded)
			.reduce((a, b) => a + b);
		this.setState({
			cartItemCount: cartItemCount,
		});
	}

	handelRemovKit(kiIndex, kit_id) {
		console.log(kiIndex, kit_id);

		this.setState({
			singleSelectedKitId: 0,
			kits: this.state.Allkits,
			addedKitFlag: false,
			// changesMade: true,
		});
	}

	handelAddRemovSet(kitId, setId, flag) {
		const { kitWithKitSet, suggestedOperationSets } = this.state;
		kitWithKitSet.map((item) => {
			if (item._id == kitId) {
				item.sets.map((subItem) => {
					if (subItem._id == setId) {
						subItem.added = flag;
					}
				});
				if (item.sets.filter((rs) => rs.added === 1).length) {
					item.cartAdded = item.sets.filter(
						(rs) => rs.added === 1
					).length;
				} else {
					item.cartAdded = 0;
				}
			}
		});

		if (suggestedOperationSets.length > 0) {
			suggestedOperationSets.map((item) => {
				if (item?.suggestedSetId[0]?._id == setId) {
					item.suggestedSetId[0].operationSuggestedFlag = flag
						? true
						: false;
				}
			});
		}

		this.setState(
			{
				kitWithKitSet,
				kitSetErrorFlag: 0,
				suggestedOperationSets,
			},
			() => {
				this.calculateItemCount();
			}
		);
	}

	handleHireCharge(kitId, setId, val) {
		const { kitWithKitSet } = this.state;
		if (val != "" && val < 0) {
			val = "";
		}

		kitWithKitSet.map((item) => {
			if (item._id == kitId) {
				item.sets.map((subItem) => {
					if (subItem._id == setId) {
						subItem.hire_charge = val;
					}
				});
			}
		});

		this.setState({
			kitWithKitSet,
			// changesMade: true,
		});
	}

	handleUpdateBookingInfo(name, value) {
		const { updateBookingInfo } = this.state;
		if (
			name == "delivery_date" ||
			name == "collection_date" ||
			name == "operation_date"
		) {
			let date = dateFormat(value, "yyyy-mm-dd");
			bookingService
				.checkHolidayDate({ date: date })
				.then((response) => {
					console.log("checkholiday", response);
					if (response.data && response.data.length !== 0) {
						if (name === "delivery_date") {
							this.setState((prevState) => ({
								deliveryDateError:
									"Delivery Date falls on a holiday.",
								operationDateError:
									prevState.operationDateError,
								collectionDateError:
									prevState.collectionDateError,
							}));
						} else if (name === "collection_date") {
							this.setState((prevState) => ({
								deliveryDateError: prevState.deliveryDateError,
								collectionDateError:
									"Collection Date falls on a holiday.",
								operationDateError:
									prevState.operationDateError,
							}));
						} else if (name === "operation_date") {
							this.setState((prevState) => ({
								deliveryDateError: prevState.deliveryDateError,
								collectionDateError:
									prevState.collectionDateError,
								operationDateError:
									"Operation Date falls on a holiday.",
							}));
						}
					} else {
						this.setState((prevState) => ({
							deliveryDateError:
								name === "delivery_date"
									? ""
									: prevState.deliveryDateError,
							operationDateError:
								name === "operation_date"
									? ""
									: prevState.operationDateError,
							collectionDateError:
								name === "collection_date"
									? ""
									: prevState.collectionDateError,
						}));
					}
				})
				.catch((error) => {
					console.error(error);
				});
		}

		if (name == "shipping_charge" && value != "" && value < 0) {
			value = "";
		}
		updateBookingInfo[name] = value;
		if (name == "po_check" && value == true) {
			updateBookingInfo["po_number"] = "";
		}
		if (name == "po_number" && value == "") {
			updateBookingInfo["po_check"] = true;
		} else if (name == "po_number" && value != "") {
			updateBookingInfo["po_check"] = false;
		}
		this.setState(
			{
				updateBookingInfo,
				// changesMade: true,
			},
			() => {
				console.log(this.state.updateBookingInfo);
			}
		);
	}

	handleBookingAddress(value, checked, ind) {
		const { updateBookingInfo, hospitalAddress } = this.state;
		hospitalAddress.map((item) => {
			item.checked = false;
		});
		hospitalAddress[ind].checked = true;
		this.setState({
			hospitalAddress,
		});

		updateBookingInfo["hospital_address"] = value;
		this.setState({
			updateBookingInfo,
			// changesMade: true,
		});
	}

	handleBookingDepartment(value, checked, ind) {
		const { updateBookingInfo, hospitalDepartment } = this.state;
		hospitalDepartment.map((item) => {
			item.checked = false;
		});
		hospitalDepartment[ind].checked = true;
		this.setState({
			hospitalDepartment,
			// changesMade: true,
		});

		updateBookingInfo["hospital_department"] = value;
		this.setState({
			updateBookingInfo,
		});
	}

	handlePickupCheckbox(value) {
		const { updateBookingInfo } = this.state;
		updateBookingInfo["pickup_location_checkbox"] = value;
		this.setState({
			updateBookingInfo,
			// changesMade: true,
		});
	}

	setSelectedOptionDeliverySlot(e) {
		var selectedDeliverySlot = [{ value: e.value, label: e.label }];
		this.setState({
			selectedDeliverySlot: selectedDeliverySlot,
			updateBookingInfo: {
				...this.state.updateBookingInfo,
				delivery_date_slot: e.value,
			},
			// changesMade: true,
		});
	}

	setSelectedOptionOperationSlots(e) {
		var selectedOperationSlots = [{ value: e.value, label: e.label }];
		this.setState({
			selectedOperationSlots: selectedOperationSlots,
			updateBookingInfo: {
				...this.state.updateBookingInfo,
				operation_date_slot: e.value,
			},
			// changesMade: true,
		});
	}

	setSelectedOptionSurgeon(e) {
		var setSelectedOptionSurgeon = [{ value: e.value, label: e.label }];
		this.setState({
			selectedSurgeon: setSelectedOptionSurgeon,
			updateBookingInfo: {
				...this.state.updateBookingInfo,
				surgeonData: [e.value],
			},
			surgeonNoteDetails: e?.notes,
		});
	}

	sendEmailConfirmHandle(flag) {
		const { updateBookingInfo } = this.state;
		updateBookingInfo["mail_sent"] = true;
		this.setState(
			{
				isEmailSend: true,
				updateBookingInfo,
				// changesMade: false,
			},
			() => {
				if (
					updateBookingInfo.delivery_date &&
					updateBookingInfo.operation_date &&
					updateBookingInfo.delivery_date_slot != "Same Day" &&
					updateBookingInfo.delivery_date.getTime() ===
						updateBookingInfo.operation_date.getTime()
				) {
					this.setState({
						sameDatesModal: true,
						checkDateFlag: true,
					});
				} else {
					this.setState({
						sameDatesModal: false,
						checkDateFlag: false,
					});

					this.handleSubmit(flag);
				}
			}
		);
	}

	saveChangeHandle(flag) {
		const { updateBookingInfo } = this.state;
		updateBookingInfo["mail_sent"] = false;
		this.setState(
			{
				updateBookingInfo,
				// changesMade: false,
			},
			() => {
				if (
					updateBookingInfo.delivery_date &&
					updateBookingInfo.operation_date &&
					updateBookingInfo.delivery_date_slot != "Same Day" &&
					updateBookingInfo.delivery_date.getTime() ===
						updateBookingInfo.operation_date.getTime()
				) {
					this.setState({
						sameDatesModal: true,
						checkDateFlag: true,
					});
				} else {
					this.setState({
						sameDatesModal: false,
						checkDateFlag: false,
					});

					this.handleSubmit(flag);
				}
			}
		);
	}

	async handleSubmit(flag) {
		this.setState({ isLoading: true });
		const { updateBookingInfo, kitWithKitSet } = this.state;
		var kitError = 0;
		var kitData = [];
		var kitSetData = [];
		let kitSetFlag = 0;
		var kitSetErrorFlag = 0;

		kitWithKitSet.map((item) => {
			item.sets
				.filter((rs) => rs.added === 1)
				.map((sub) => {
					kitSetFlag = 1;
					kitError = 1;
					kitSetData.push({
						id: sub._id,
						hire_charge: sub.hire_charge,
						kitId: item._id,
					});
				});
			if (item.sets.filter((rs) => rs.added === 1).length) {
				kitData.push({
					id: item._id,
					hire_charge: item.hire_charge,
					quantity: item.quantity,
				});
			}
		});

		updateBookingInfo["return_date"] = "";

		if (updateBookingInfo["collection_date"] !== "") {
			let return_date = new Date(updateBookingInfo["collection_date"]);
			return_date = await this.calculateReturnDate(return_date);
			// return_date = return_date.setDate(return_date.getDate() + 1);
			updateBookingInfo["return_date"] = dateFormat(
				return_date,
				"yyyy-mm-dd"
			);
		}
		if (updateBookingInfo["booking_type"] == "consignment") {
			updateBookingInfo["operation_date"] =
				updateBookingInfo["collection_date"];
		}

		updateBookingInfo["kitData"] = kitData;
		updateBookingInfo["kitSetData"] = kitSetData;

		console.log(kitSetFlag);

		if (
			updateBookingInfo["hospitalId"] != "" &&
			updateBookingInfo["po_number"] != "" &&
			updateBookingInfo["delivery_date"] != "" &&
			updateBookingInfo["operation_date"] != "" &&
			updateBookingInfo["collection_date"] != "" &&
			updateBookingInfo["kitData"].length != 0 &&
			updateBookingInfo["kitSetData"].length != 0
		) {
			kitSetErrorFlag = 1;
			this.setState({
				kitSetErrorFlag: 0,
			});
		} else {
			kitSetErrorFlag = 0;
			if (kitError == 1) {
				kitSetErrorFlag = 1;
				this.setState({
					kitSetErrorFlag: 0,
				});
			} else {
				this.setState({
					isLoading: false,
					kitSetErrorFlag: 1,
				});
			}
		}

		updateBookingInfo["log_status"] = "updated";

		if (updateBookingInfo["status"] == "pending") {
			updateBookingInfo["reject_status"] = "booked";
			updateBookingInfo["log_status"] = "booked";
		} else {
			updateBookingInfo["reject_status"] = "";
		}

		updateBookingInfo["log_by_id"] = this.state.user.data._id;

		//console.log(kitSetErrorFlag);

		if (kitSetErrorFlag == 1) {
			var datass = {
				additional_notes: updateBookingInfo["additional_notes"],
				additional_reps: updateBookingInfo["additional_reps"],
				assigned_rep: updateBookingInfo["assigned_rep"],
				booking_type: updateBookingInfo["booking_type"],
				collection_date: dateFormat(
					updateBookingInfo["collection_date"],
					"yyyy-mm-dd"
				),
				delivery_date: dateFormat(
					updateBookingInfo["delivery_date"],
					"yyyy-mm-dd"
				),
				delivery_date_slot: updateBookingInfo["delivery_date_slot"],
				hospitalId: updateBookingInfo["hospitalId"],
				hospital_address: updateBookingInfo["hospital_address"],
				hospital_department: updateBookingInfo["hospital_department"],
				id: updateBookingInfo["id"],
				kitData: updateBookingInfo["kitData"],
				kitSetData: updateBookingInfo["kitSetData"],
				log_by_id: updateBookingInfo["log_by_id"],
				log_status: updateBookingInfo["log_status"],
				operation_date: dateFormat(
					updateBookingInfo["operation_date"],
					"yyyy-mm-dd"
				),
				operation_date_slot: updateBookingInfo["operation_date_slot"],
				operation_name: updateBookingInfo["operation_name"],
				other_opration: updateBookingInfo["other_opration"],
				po_number: updateBookingInfo["po_number"],
				reject_status: updateBookingInfo["reject_status"],
				return_date: updateBookingInfo["return_date"],
				shipping_charge: updateBookingInfo["shipping_charge"],
				status: updateBookingInfo["status"],
				surgeonData: updateBookingInfo["surgeonData"],
				mail_sent: this.state.isEmailSend
					? true
					: updateBookingInfo["mail_sent"],
				pickup_location_checkbox:
					updateBookingInfo["pickup_location_checkbox"],
				pickup_location_text: updateBookingInfo["pickup_location_text"],
				po_check: updateBookingInfo["po_check"],
				bilateral_case: updateBookingInfo["bilateral_case"],
				checkDoubleBooked: flag,
			};
			this.props.dispatch(bookingActions.update(datass));
		}
	}

	kitSetDetailModalClose() {
		this.setState({
			kitSetDetailModal: false,
			kitSetData: [],
		});
	}

	showKitDetails(kitDetails) {
		if (this.state.kitDetailsClickFlag == 1) {
			this.setState({
				kitDetails: kitDetails,
				kitSetDetailModal: true,
				kitDetailsClickFlag: 0,
			});
		}
	}

	calculateReturnDate = async (collectionDate) => {
		let return_date = new Date(collectionDate);
		return_date.setDate(return_date.getDate() + 1);
		const holidays = await bookingService
			.getHolidayList({ page: "", limit: "" })
			.then((response) =>
				response?.data?.map((holiday) => new Date(holiday.date))
			)
			.catch((error) => {
				console.error("Error fetching holiday list: ", error);
				return [];
			});

		while (this.isHolidayOrWeekend(return_date, holidays)) {
			return_date.setDate(return_date.getDate() + 1);
		}
		this.state.updateBookingInfo["return_date"] = dateFormat(
			return_date,
			"yyyy-mm-dd"
		);
		return return_date;
	};

	isHolidayOrWeekend = (date, holidays) => {
		const day = date.getDay();
		const isWeekend = day === 6 || day === 0;
		const isHoliday = holidays?.some(
			(holiday) =>
				holiday.getFullYear() === date.getFullYear() &&
				holiday.getMonth() === date.getMonth() &&
				holiday.getDate() === date.getDate()
		);
		return isWeekend || isHoliday;
	};

	handleKitDetails(id) {
		this.setState({
			kitDetailsClickFlag: 1,
		});

		this.props.dispatch(setActions.getById(id));
	}

	capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	checkAvalability(
		bookingKitSets,
		setd,
		setId,
		index,
		ind,
		kitId,
		showItems
	) {
		const { updateBookingInfo } = this.state;
		// console.log(setId,index,ind);
		var htm = [];

		var currentDate = updateBookingInfo.delivery_date;
		var delivery_date = updateBookingInfo.delivery_date;
		var endDate = updateBookingInfo.delivery_date;
		var falgForHtm = 0;
		var date = "";
		var separator = "-";
		var fulldate = "";
		var nextDate = [];
		var holiday = "No";
		var isConsinment = 0;
		if (setd.quarantine_check && setd.missing_stock_check) {
			htm = <span className="capsule purple-color-text">Missing Stock and Quarantine</span>;
		}
		else if (setd.missing_stock_check) {
			htm = <span className="capsule brown-color-text">Missing Stock</span>;
		} else if (setd.quarantine_check) {
			htm = <span className="capsule orange-color-text">Quarantine</span>;
		} else if (currentDate !== "" && endDate !== "") {
			currentDate = new Date(currentDate);
			endDate = new Date(endDate);
			var dates = [];
			while (currentDate <= endDate) {
				date = `${currentDate.getFullYear()}${separator}${
					currentDate.getMonth() + 1 < 10
						? `0${currentDate.getMonth() + 1}`
						: `${currentDate.getMonth() + 1}`
				}${separator}${
					currentDate.getDate() < 10
						? `0${currentDate.getDate()}`
						: `${currentDate.getDate()}`
				}`;

				dates.push({ fulldate: date });
				currentDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate() + 1 // Will increase month if over range
				);
			}

			if (bookingKitSets.length == 0) {
				htm.push(<span className="capsule green-dim">Available</span>);
				currentDate = new Date(delivery_date);
				endDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate() + 6
				);

				while (currentDate <= endDate) {
					holiday = "No";
					if (
						currentDate.getDay() == 6 ||
						currentDate.getDay() == 0
					) {
						holiday = "holiday";
					}

					nextDate.push({
						date:
							currentDate.getDate() +
							" " +
							dayName[currentDate.getDay()],
						holiday: holiday,
						status: "",
					});

					currentDate = new Date(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate() + 1 // Will increase month if over range
					);
				}
			} else {
				dates &&
					dates.map((diffdate) => {
						fulldate = diffdate.fulldate;
						bookingKitSets
							.filter((rs) => rs.setId == setId)
							.map((bookingSt) => {
								if (
									bookingSt.set_return_date != null &&
									bookingSt?.bookings[0]?.delivery_date <=
										fulldate &&
									bookingSt.set_return_date >= fulldate
								) {
									falgForHtm += 1;
								} else if (
									bookingSt.set_return_date == null &&
									bookingSt.bookings &&
									bookingSt.bookings.filter(
										(rs) =>
											rs._id != updateBookingInfo.id &&
											rs.delivery_date <= fulldate &&
											rs.collection_date >= fulldate
									).length
								) {
									falgForHtm += 1;
								}

								if (
									bookingSt.bookings &&
									bookingSt.bookings.filter(
										(rs) =>
											rs.booking_type != "loan" &&
											rs._id != updateBookingInfo.id &&
											rs.delivery_date <= fulldate &&
											rs.collection_date >= fulldate
									).length
								) {
									isConsinment = 1;
								}
							});
					});

				if (falgForHtm > 0) {
					htm.push(
						<span className="capsule red-dim">Not Available</span>
					);
				}

				if (falgForHtm == 0) {
					htm.push(
						<span className="capsule green-dim">Available</span>
					);
				}

				currentDate = new Date(delivery_date);

				endDate = new Date(
					currentDate.getFullYear(),
					currentDate.getMonth(),
					currentDate.getDate() + 6
				);

				while (currentDate <= endDate) {
					holiday = "No";
					falgForHtm = 0;
					if (
						currentDate.getDay() == 6 ||
						currentDate.getDay() == 0
					) {
						holiday = "holiday";
					}

					fulldate = `${currentDate.getFullYear()}${separator}${
						currentDate.getMonth() + 1 < 10
							? `0${currentDate.getMonth() + 1}`
							: `${currentDate.getMonth() + 1}`
					}${separator}${
						currentDate.getDate() < 10
							? `0${currentDate.getDate()}`
							: `${currentDate.getDate()}`
					}`;

					bookingKitSets
						.filter((rs) => rs.setId == setId)
						.map((bookingSt) => {
							if (
								bookingSt.set_return_date != null &&
								bookingSt?.bookings[0]?.delivery_date <=
									fulldate &&
								bookingSt.set_return_date >= fulldate
							) {
								falgForHtm += 1;
							} else if (
								bookingSt.set_return_date == null &&
								bookingSt.bookings &&
								bookingSt.bookings.filter(
									(rs) =>
										rs.delivery_date <= fulldate &&
										rs.collection_date >= fulldate
								).length
							) {
								falgForHtm += 1;
							}
						});

					if (falgForHtm > 0) {
						nextDate.push({
							date:
								currentDate.getDate() +
								" " +
								dayName[currentDate.getDay()],
							holiday: "na",
							status: " Not Available",
						});
					}

					if (falgForHtm == 0) {
						nextDate.push({
							date:
								currentDate.getDate() +
								" " +
								dayName[currentDate.getDay()],
							holiday: holiday,
							status: "Available",
						});
					}
					currentDate = new Date(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate() + 1 // Will increase month if over range
					);
				}
			}
		} else {
			htm.push(<span className="capsule green-dim">Available</span>);
		}

		if (showItems == 2) {
			showItems = setd.added;
		}

		if (isConsinment == 0 && setd.added == showItems) {
			return (
				<React.Fragment>
					<td>
						<div className="mb-2">
							{/* <a href="javascript:;" onClick={()=>this.handleKitDetails(setd._id)} data-toggle="modal" data-target="#kitDetails">{setd.set_name}</a> */}
							<span>{setd.set_name}</span>
						</div>
						{htm}
						<div key="2">
							<div
								className="table-chart"
								style={{
									width: "auto",
									display: "inline-block",
								}}
							>
								<table className="mt-2 main-table table-sm">
									<thead>
										<tr
											style={{
												borderTop: "1px solid #DDD",
											}}
										>
											{nextDate &&
												nextDate.map((next, indxx) => (
													<th
														key={indxx}
														in={indxx}
														className={
															(indxx == 0
																? " first_col "
																: " ") +
															next.holiday
														}
														style={{
															width: "30px",
														}}
													>
														{next.date}
													</th>
												))}
										</tr>
									</thead>
								</table>
							</div>
						</div>
					</td>
					<td style={{ width: "120px", "vertical-align": "bottom" }}>
						<label>Hire Charges (Per Set)</label>
						<Input
							type="number"
							min="0"
							className="form-control"
							value={setd.hire_charge}
							onChange={(e) =>
								this.handleHireCharge(
									kitId,
									setId,
									e.target.value
								)
							}
							name=""
							placeholder="£"
						/>
					</td>

					<td className="text-right align-bottom" width="120">
						{setd.added == 0 && (
							<a
								href="javascript:;"
								onClick={() =>
									this.handelAddRemovSet(kitId, setId, 1)
								}
								className="btn btn-primary addnow"
							>
								<i className="fa fa-plus"></i> Add
							</a>
						)}

						{setd.added == 1 && (
							<div
								id="added"
								className="mb-3 position-relative"
								style={{ width: "110px" }}
							>
								<span className="addmsg">
									<i className="fa fa-check"></i> Added{" "}
								</span>
								<a
									href="javascript:"
									onClick={() =>
										this.handelAddRemovSet(kitId, setId, 0)
									}
									className="ml-1"
								>
									<i className="fa fa-trash"></i>
								</a>
							</div>
						)}
					</td>
				</React.Fragment>
			);
		}
	}

	checkAvalabilityForConnsignment(kit) {
		const { updateBookingInfo } = this.state;

		var currentDate = updateBookingInfo.delivery_date;
		var endDate = updateBookingInfo.collection_date;
		var date = "";
		var dates = [];
		var separator = "-";
		var fulldate = "";
		var isConsinment = 0;
		var totalConsinment = 0;

		kit.sets.map((setd) => {
			// console.log(ind,"test ",index);

			currentDate = updateBookingInfo.delivery_date;
			endDate = updateBookingInfo.collection_date;
			date = "";
			dates = [];
			separator = "-";
			fulldate = "";
			isConsinment = 0;

			if (currentDate != "" && endDate != "") {
				currentDate = new Date(currentDate);
				endDate = new Date(endDate);

				while (currentDate <= endDate) {
					date = `${currentDate.getFullYear()}${separator}${
						currentDate.getMonth() + 1 < 10
							? `0${currentDate.getMonth() + 1}`
							: `${currentDate.getMonth() + 1}`
					}${separator}${
						currentDate.getDate() < 10
							? `0${currentDate.getDate()}`
							: `${currentDate.getDate()}`
					}`;

					dates.push({ fulldate: date });
					currentDate = new Date(
						currentDate.getFullYear(),
						currentDate.getMonth(),
						currentDate.getDate() + 1 // Will increase month if over range
					);
				}
				// console.log(setd.setId,"ln");
				if (setd.bookingKitSets.length > 0) {
					dates &&
						dates.map((diffdate) => {
							fulldate = diffdate.fulldate;

							setd.bookingKitSets &&
								setd.bookingKitSets
									.filter((rs) => rs.setId == setd._id)
									.map((bookingSt) => {
										if (
											bookingSt.bookings &&
											bookingSt.bookings.filter(
												(rs) =>
													rs.booking_type != "loan" &&
													rs._id !=
														updateBookingInfo.id &&
													rs.delivery_date <=
														fulldate &&
													rs.collection_date >=
														fulldate
											).length
										) {
											isConsinment = 1;
										}
									});
						});
					totalConsinment += isConsinment;
				}
			}
		});

		if (totalConsinment == kit.sets.length && kit.sets.length > 0) {
			return (
				<React.Fragment>
					<tr>
						<td colSpan="3" className=" red-dim">
							Consignment already booked for this duration
						</td>
					</tr>
				</React.Fragment>
			);
		}
	}

	setSurgeonEdit() {
		const { updateBookingInfo } = this.state;
		var defaultSurgeon = [];
		var defaultSurgeonNotes = "";

		console.log("11122222", updateBookingInfo.surgeonData);
		if (updateBookingInfo.surgeonData.length > 0) {
			console.log("ssssssss", updateBookingInfo.surgeonData[0]);
			this.state.surgeonList &&
				this.state.surgeonList
					.filter(
						(val) => val.value === updateBookingInfo.surgeonData[0]
					)
					.map((surValue) => {
						defaultSurgeon.push({
							value: surValue.value,
							label: surValue.label,
							notes: surValue?.notes,
						});
						defaultSurgeonNotes = surValue?.notes;
					});
			console.log("defaultSurgeon", defaultSurgeon);
		}

		this.setState(
			{
				selectedSurgeon: defaultSurgeon,
				surgeonNoteDetails: defaultSurgeonNotes,
			},
			() => {
				console.log(this.state.surgeonList);
				console.log(this.state.selectedSurgeon);
			}
		);
	}

	setSurgeonList() {
		surgeonService.getSurgeonsList({ search: "" }).then((response) => {
			var surgeonList = [];
			response.data.map((item) => {
				var option = {
					value: item._id,
					label: item.first_name + " " + item.last_name,
					notes: item?.notes ? item?.notes : "",
				};
				surgeonList.push(option);
			});

			this.setState(
				{
					surgeonList: surgeonList,
				},
				() => {
					this.setSurgeonEdit();
				}
			);
		});
	}

	addSurgeonModal() {
		this.setState({
			addSurgeonModal: true,
		});
	}

	addNewSurgeonModalClose() {
		this.setState({ addSurgeonModal: false });
		this.setSurgeonList();
	}

	addHospitalModal() {
		this.setState({
			addNewHospitalModalProp: true,
		});
	}

	addNewHospitalModalClose() {
		this.setState({ addNewHospitalModalProp: false });
		this.showHospitalsList();
	}

	componentDidMount() {
		this.getList();
		window.scrollTo({ top: 0 });
		const timer = setTimeout(() => {
			this.getSingleBooking(this.props.match.params.id);
		}, 1500);
		return () => clearTimeout(timer);
	}

	setSelectedOptionBilateralCase(e) {
		var selectedBilateralCase = [{ value: e.value, label: e.label }];
		this.setState({
			selectedBilateralCase: selectedBilateralCase,
			updateBookingInfo: {
				...this.state.updateBookingInfo,
				bilateral_case: e.value,
			},
			changesMade: true,
		});
	}

	editHospitalModalOpen(id) {
		this.setState({
			editHospitalId: id,
			editHospitalModal: !this.state.editModal,
		});
	}

	editHospitalModalClose() {
		this.setState({
			editHospitalModal: false,
			editHospitalId: 0,
		});
	}

	refreshHospitalList() {
		if (this.state.selectedHospital.length > 0) {
			this.showHospitalsList();
			this.getHospitalAddressList();
		}
	}

	setSugestedSelectedOprationSet(e) {
		const result = this.state.suggestedOperationSets;
		result[e.setIndex].suggestedSetId[0].operationSuggestedFlag = true;
		var selectedKit = [{ value: e.value, label: e.label }];
		let selectedSetInfo = [
			{ setName: e.setName, setId: e.setId, kitId: e.value },
		];
		this.setState(
			{
				selectedKit: selectedKit,
				cartFlag: false,
				selectedSetInfo: selectedSetInfo,
				suggestedOperationSets: result,
			},
			() => {
				this.handleAddKit();
			}
		);
	}

	warningModalClose() {
		this.setState({
			addedKitFlag: false,
		});
	}

	checkDateModalClose() {
		this.setState({
			checkDateFlag: false,
		});
	}

	doubleBookingDetailModalClose() {
		this.setState({
			doubleBookingDetailModal: false,
			doubleBookingInfo: [],
		});
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		console.log(nextProps);

		if (nextProps.bookingInfo !== undefined) {
			this.setBookingData(nextProps.bookingInfo);
		}

		if (nextProps.repsList !== undefined) {
			this.showRepsList(nextProps.repsList);
		}

		if (
			nextProps.kitListDropdown !== undefined &&
			this.state.kitListFlag == 0
		) {
			this.showKitsList(nextProps.kitListDropdown);
		}

		if (nextProps.kitSetList !== undefined) {
			this.showKitsSetList(nextProps.kitSetList);
		}

		if (nextProps.editSet == true) {
			this.showKitDetails(nextProps.set);
		}

		if (nextProps.opLists !== undefined) {
			this.showOperationsList(nextProps.opLists);
		}

		if (nextProps.redirect == true) {
			this.setState({ isLoading: false });
		}

		if (
			nextProps.doubleBookingList !== undefined &&
			nextProps?.doubleBookingList?.length
		) {
			this.setState({
				doubleBookingInfo: nextProps.doubleBookingList,
				doubleBookingDetailModal: true,
				isLoading: false,
			});
		}
		if (
			nextProps.sameDateList !== undefined &&
			nextProps?.sameDateList?.length
		) {
			this.setState({
				sameDatesInfo: nextProps.sameDateList,
				sameDatesModal: true,
				isLoading: false,
			});
		}
	}

	render() {
		const { redirect, error } = this.props;
		// const { changesMade } = this.state;
		if (
			redirect &&
			this.state.updateBookingInfo.booking_type != "consignment"
		) {
			location.replace("/admin/bookings");
			//return <Redirect to="/admin/bookings" />;
		}
		if (
			redirect &&
			this.state.updateBookingInfo.booking_type == "consignment"
		) {
			location.replace("/admin/bookings/consignment");
			//return <Redirect to="/admin/bookings/consignment" />;
		}
		if (!this.state.user) {
			return <Redirect to="/" />;
		}
		if (this.state.user.data.user_role !== "admin") {
			return <Redirect to="/" />;
		}

		const { surgeonNoteDetails, isLoading } = this.state;

		return (
			<div>
				<Navbar activePage="bookings" />
				<div className="container">
					<h1 className="date-scroll pt-4">
						<a onClick={() => history.goBack()}>
							<i className="fa fa-long-arrow-left smbtn"></i>{" "}
							<strong>Back</strong>
						</a>
					</h1>
					<div className="row pt-4">
						<div className="col-lg-8">
							<div className="d-card">
								<h4>Basic Details</h4>
								<div className="form-group">
									<label>Booking Type</label>
									<div
										className="position_up"
										style={{ zIndex: 9 }}
									>
										<Select
											value={
												this.state.selectedBookingType
											}
											onChange={
												this
													.setSelectedOptionBookingType
											}
											options={this.state.bookingType}
										/>
										{error ? (
											<span className="text-danger">
												{error.type ? error.type : ""}
											</span>
										) : (
											""
										)}
									</div>
								</div>
								<div className="form-group">
									<label>Hospital Name</label>
									<span className="pull-right">
										<a
											href="javascript:;"
											className="btn-link"
											onClick={() =>
												this.addHospitalModal()
											}
										>
											+ Add New Hospital
										</a>
									</span>

									<div
										className="position_up"
										style={{ zIndex: 8 }}
									>
										<Select
											value={this.state.selectedHospital}
											onChange={
												this.setSelectedOptionHospital
											}
											options={this.state.hospitals}
											maxMenuHeight={190}
										/>
									</div>
								</div>

								{this.state.selectedHospital.length > 0 ? (
									<div className="row position-relative">
										<div className="edit_fld">
											<div className="">
												{" "}
												<i
													onClick={() =>
														this.editHospitalModalOpen(
															this.state
																.selectedHospital[0]
																.value
														)
													}
													className="fa fa-pencil"
												></i>
											</div>
										</div>
										<div className="info col-md-6">
											<h6>Address</h6>
											<hr />
											{this.state.hospitalAddress.map(
												(item, ind) => (
													<div
														className="info-left"
														style={{
															marginLeft:
																"1.25rem",
														}}
														key={item._id}
													>
														<Input
															type="radio"
															id={item._id}
															name="hospitalAddress"
															value={item.name}
															checked={
																item.checked ==
																true
															}
															onChange={(e) =>
																this.handleBookingAddress(
																	e.target
																		.value,
																	e.target
																		.checked,
																	ind,
																	item
																)
															}
														/>
														<strong>
															{" "}
															{
																this.state
																	.hospitalContactPerson
															}
														</strong>
														<div>
															<small>
																Location:{" "}
																{item.name}
															</small>
														</div>
														<div>
															<small>
																Email:{" "}
																{
																	this.state
																		.hospitalContactPersonEmail
																}
															</small>
														</div>
														<div>
															<small>
																Phone:{" "}
																{
																	this.state
																		.hospitalContactPersonPhone
																}
															</small>
														</div>
													</div>
												)
											)}
										</div>
										<div className="info col-md-6">
											<h6>Department</h6>
											<hr />
											{this.state.hospitalDepartment.map(
												(item, ind) => (
													<div
														className="info-left"
														style={{
															marginLeft:
																"1.25rem",
														}}
														key={item._id}
													>
														<Input
															type="radio"
															id={item._id}
															name="hospitalDepartment"
															value={item.name}
															checked={
																item.checked ==
																true
															}
															onChange={(e) =>
																this.handleBookingDepartment(
																	e.target
																		.value,
																	e.target
																		.checked,
																	ind,
																	item
																)
															}
														/>
														<div>
															<small>
																{item.name}
															</small>
														</div>
													</div>
												)
											)}
										</div>
										<div className="col-md-12">
											<div className="form-group mt-3">
												<span className="">
													<Input
														type="checkbox"
														className="ml-0 form-check-input"
														value={
															this.state
																.updateBookingInfo
																.pickup_location_checkbox
														}
														checked={
															this.state
																.updateBookingInfo
																.pickup_location_checkbox
														}
														onChange={(e) =>
															this.handlePickupCheckbox(
																e.target.checked
															)
														}
													/>
												</span>
												<label className="ml-4 ">
													Is Pickup Location And
													Contact Details Different
												</label>
												{this.state.updateBookingInfo
													.pickup_location_checkbox ? (
													<Input
														type="text"
														className="form-control"
														value={
															this.state
																.updateBookingInfo
																.pickup_location_text
														}
														onChange={(e) =>
															this.handleUpdateBookingInfo(
																"pickup_location_text",
																e.target.value
															)
														}
													/>
												) : (
													""
												)}
											</div>
										</div>
									</div>
								) : (
									""
								)}

								<div className="form-group">
									<label>Surgeon Name</label>
									<span className="pull-right">
										<a
											href="javascript:;"
											className="btn-link"
											onClick={() =>
												this.addSurgeonModal()
											}
										>
											+ Add New Surgeon
										</a>
									</span>

									<div
										className="info position_up"
										style={{ zIndex: 7 }}
									>
										<Select
											value={this.state.selectedSurgeon}
											onChange={
												this.setSelectedOptionSurgeon
											}
											options={this.state.surgeonList}
											maxMenuHeight={190}
										/>
										{error ? (
											<span className="text-danger">
												{error.surgeonData
													? error.surgeonData
													: ""}
											</span>
										) : (
											""
										)}
									</div>

									{surgeonNoteDetails != "" ? (
										<div
											className="info-left"
											style={{ marginTop: "0.75rem" }}
										>
											<div>
												<strong>Note : </strong>{" "}
												<small>
													{surgeonNoteDetails}
												</small>
											</div>
										</div>
									) : (
										""
									)}
								</div>

								<div className="form-group">
									<label>Operation Name</label>
									<div
										className="info position_up"
										style={{ zIndex: 4 }}
									>
										<Select
											value={this.state.selectedOperation}
											onChange={
												this.setSelectedOptionOperation
											}
											options={this.state.operationsList}
											maxMenuHeight={190}
										/>
									</div>
								</div>

								{this.state.updateBookingInfo.operation_name ==
									"Other" && (
									<div className="form-group">
										<label>Other Operation </label>
										<Input
											type="text"
											className="form-control"
											value={
												this.state.updateBookingInfo
													.other_opration
											}
											onChange={(e) =>
												this.handleUpdateBookingInfo(
													"other_opration",
													e.target.value
												)
											}
										/>
										{error ? (
											<span className="text-danger">
												{error.other_opration
													? error.other_opration
													: ""}
											</span>
										) : (
											""
										)}
									</div>
								)}

								<div className="form-group">
									<label>
										Bilateral Case{" "}
										<span className="text-danger">*</span>
									</label>
									<div
										className=" info position_up"
										style={{ zIndex: 3 }}
									>
										<Select
											value={
												this.state.selectedBilateralCase
											}
											onChange={
												this
													.setSelectedOptionBilateralCase
											}
											options={bilateralCaseList}
											maxMenuHeight={190}
										/>
										{error ? (
											<span className="text-danger">
												{error.bilateral_case
													? error.bilateral_case
													: ""}
											</span>
										) : (
											""
										)}
									</div>
								</div>

								<div className="form-group">
									<label>PO Number</label>

									<Input
										type="text"
										className="form-control"
										value={
											this.state.updateBookingInfo
												.po_number
										}
										onChange={(e) =>
											this.handleUpdateBookingInfo(
												"po_number",
												e.target.value
											)
										}
									/>
									{error ? (
										<span className="text-danger">
											{error.po_number
												? error.po_number
												: ""}
										</span>
									) : (
										""
									)}
								</div>
								<div className="form-group">
									<span className="mr-1">
										<Input
											type="checkbox"
											className="ml-1"
											value={
												this.state.updateBookingInfo
													.po_check
											}
											checked={
												this.state.updateBookingInfo
													.po_check
											}
											onChange={(e) =>
												this.handleUpdateBookingInfo(
													"po_check",
													e.target.checked
												)
											}
										/>
									</span>
									<label className="ml-4">Awaiting PO</label>
								</div>
							</div>
							<div className="d-card mt-4">
								<h4>Purchase/Hire Details</h4>
								<div className="row purchase_date">
									<div
										className={
											this.state.updateBookingInfo
												.booking_type != "consignment"
												? "col-md-6"
												: "col-md-12"
										}
									>
										<div className="form-group">
											<label>
												Delivery Date{" "}
												<span className="text-danger">
													*
												</span>
											</label>
											<DatePicker
												selected={
													this.state.updateBookingInfo
														.delivery_date
												}
												onChange={(date) =>
													this.handleUpdateBookingInfo(
														"delivery_date",
														date
													)
												}
												dateFormat="dd-MM-yyyy EEE"
												className="small"
												id="datepicker"
												autoComplete="off"
											/>
											{this.state.deliveryDateError && (
												<div className="error-message">
													<span className="text-danger">
														{
															this.state
																.deliveryDateError
														}
													</span>
												</div>
											)}
											{error ? (
												<div>
													<span className="text-danger">
														{error.delivery_date
															? error.delivery_date
															: ""}
													</span>
												</div>
											) : (
												""
											)}
											{this.state.updateBookingInfo
												.delivery_date != "" &&
												(new Date(
													this.state.updateBookingInfo.delivery_date
												).getDay() == 6 ||
													new Date(
														this.state.updateBookingInfo.delivery_date
													).getDay() == 0) && (
													<div>
														<span className="text-danger">
															Selected date is a
															weekend/holiday but
															you can still make a
															booking
														</span>
													</div>
												)}
										</div>
									</div>
									{this.state.updateBookingInfo
										.booking_type != "consignment" && (
										<div className="col-md-6">
											<div className="form-group">
												<label></label>
												<div className="mt-2 position_up">
													<Select
														value={
															this.state
																.selectedDeliverySlot
														}
														onChange={
															this
																.setSelectedOptionDeliverySlot
														}
														options={deliverySlots}
														className="small"
													/>
												</div>
											</div>
										</div>
									)}

									{this.state.updateBookingInfo
										.booking_type != "consignment" && (
										<React.Fragment>
											<div className="col-md-6">
												<div className="form-group">
													<label>
														Operation Date{" "}
														<span className="text-danger">
															*
														</span>
													</label>
													<DatePicker
														selected={
															this.state
																.updateBookingInfo
																.operation_date
														}
														onChange={(date) =>
															this.handleUpdateBookingInfo(
																"operation_date",
																date
															)
														}
														dateFormat="dd-MM-yyyy EEE"
														className="small"
														id="datepicker2"
														autoComplete="off"
													/>
													{this.state
														.operationDateError && (
														<div className="error-message">
															<span className="text-danger">
																{
																	this.state
																		.operationDateError
																}
															</span>
														</div>
													)}
													{error ? (
														<span className="text-danger">
															{error.operation_date
																? error.operation_date
																: ""}
														</span>
													) : (
														""
													)}
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-group">
													<label></label>
													<div
														className="mt-2 position_up"
														style={{ zIndex: 2 }}
													>
														<Select
															value={
																this.state
																	.selectedOperationSlots
															}
															onChange={
																this
																	.setSelectedOptionOperationSlots
															}
															options={
																operationSlots
															}
															className="small"
														/>
													</div>
												</div>
											</div>
										</React.Fragment>
									)}
									<div className="col-md-12">
										<div className="form-group">
											<label>Collection Date</label>
											<DatePicker
												selected={
													this.state.updateBookingInfo
														.collection_date
												}
												onChange={(date) =>
													this.handleUpdateBookingInfo(
														"collection_date",
														date
													)
												}
												dateFormat="dd-MM-yyyy EEE"
												className="small"
												id="datepicker3"
												autoComplete="off"
											/>
											{this.state.collectionDateError && (
												<div className="error-message">
													<span className="text-danger">
														{
															this.state
																.collectionDateError
														}
													</span>
												</div>
											)}
											{error ? (
												<div>
													<span className="text-danger">
														{error.collection_date
															? error.collection_date
															: ""}
													</span>
												</div>
											) : (
												""
											)}
											{this.state.updateBookingInfo
												.collection_date != "" &&
												(new Date(
													this.state.updateBookingInfo.collection_date
												).getDay() == 6 ||
													new Date(
														this.state.updateBookingInfo.collection_date
													).getDay() == 0) && (
													<div>
														<span className="text-danger mt-2">
															Selected date is a
															weekend/holiday but
															you can still make a
															booking
														</span>
													</div>
												)}
										</div>
									</div>
								</div>

								<div className="mt-4">
									<table className="table mb-0 mobile_full1">
										<tbody>
											{this.state.suggestedOperationSets
												.length > 0 && (
												<React.Fragment>
													<tr>
														<th
															colSpan="3"
															className="w-100 border-top-0"
														>
															<div className="mb-2">
																<strong>
																	Suggested
																	Sets
																</strong>
																<hr />
															</div>
															{this.state.suggestedOperationSets.map(
																(
																	sugSet,
																	sugInd
																) => (
																	<React.Fragment
																		key={
																			sugInd
																		}
																	>
																		{this.state.kitWithKitSet.filter(
																			(
																				filterKit
																			) =>
																				filterKit._id ==
																				sugSet
																					.suggestedSetId[0]
																					?._id
																		)
																			.length ==
																		0 ? (
																			sugSet
																				?.suggestedSetId[0]
																				?.set_name ? (
																				<a
																					className={
																						sugSet
																							.suggestedSetId[0]
																							?.operationSuggestedFlag
																							? "mr-3 my-2 btn btn-small btn-default dropdown-disabled"
																							: "mr-3 my-2 btn btn-small btn-default"
																					}
																					href="javascript:;"
																					//disabled={sugSet.suggestedSetId[0]?.operationSuggestedFlag ? true : false}
																					onClick={
																						sugSet
																							.suggestedSetId[0]
																							?.operationSuggestedFlag
																							? () => {}
																							: () =>
																									this.setSugestedSelectedOprationSet(
																										{
																											value: sugSet
																												.suggestedSetId[0]
																												.kitID[0]
																												?._id,
																											label: sugSet
																												.suggestedSetId[0]
																												.kitID[0]
																												?.name,
																											setId: sugSet
																												.suggestedSetId[0]
																												._id,
																											setName:
																												sugSet
																													.suggestedSetId[0]
																													.set_name,
																											setIndex:
																												sugInd,
																										}
																									)
																					}
																				>
																					{
																						sugSet
																							.suggestedSetId[0]
																							?.set_name
																					}{" "}
																					<i
																						className={
																							sugSet
																								.suggestedSetId[0]
																								?.operationSuggestedFlag
																								? "fa fa-minus ml-2"
																								: "fa fa-plus ml-2"
																						}
																					></i>{" "}
																				</a>
																			) : (
																				""
																			)
																		) : (
																			<a
																				disabled
																				className="mr-3 my-2 btn btn-small btn-default"
																				href="javascript:;"
																			>
																				{
																					sugSet
																						.suggestedSetId[0]
																						?.set_name
																				}{" "}
																				<i className="fa fa-plus ml-2"></i>{" "}
																			</a>
																		)}
																	</React.Fragment>
																)
															)}
														</th>
													</tr>
												</React.Fragment>
											)}
										</tbody>
									</table>
								</div>

								<div className="mt-4">
									<table className="table">
										<tbody>
											{this.state.updateBookingInfo
												.status == "pending" && (
												<tr>
													<th
														colSpan="2"
														className="text-center"
													>
														Kit Selected By Reps
													</th>
												</tr>
											)}
											{this.state.kitWithKitSet &&
												this.state.updateBookingInfo
													.status == "pending" &&
												this.state.kitWithKitSet
													.filter(
														(item) =>
															(item.quantity >
																0 ||
																item.hire_charge >
																	0) &&
															item.cartAdded == 0
													)
													.map((kit, index) => (
														<React.Fragment
															key={index}
														>
															<tr>
																<th colSpan="2">
																	<span className="mr-4">
																		{
																			kit.name
																		}
																		{kit
																			.kitDocument
																			.length >
																			0 && (
																			<span className="ml-1">
																				<a
																					id={
																						"UncontrolledTooltipPdf1" +
																						index
																					}
																					target="_blank"
																					rel="noreferrer"
																					className="download-info badge1 badge-info1"
																					href={
																						process
																							.env
																							.REACT_APP_APIURL +
																						"assets/uploads/kitDocuments/" +
																						kit
																							.kitDocument[0]
																							.name
																					}
																				>
																					Download
																					Tray
																					List
																				</a>
																			</span>
																		)}
																	</span>

																	<span
																		style={{
																			"vertical-align":
																				"bottom",
																		}}
																		className="small"
																	>
																		{(kit.hire_charge >
																			0 ||
																			kit.quantity >
																				0) && (
																			<React.Fragment>
																				<span>
																					Requested
																					Quantity
																					:{" "}
																					{
																						kit.quantity
																					}
																				</span>{" "}
																				&nbsp;
																				<span>
																					Hire
																					Charges
																					(Per
																					Set)
																					:{" "}
																					{
																						kit.hire_charge
																					}
																				</span>
																			</React.Fragment>
																		)}
																	</span>
																</th>
																<td className="text-right"></td>
															</tr>
														</React.Fragment>
													))}
											{this.state.kitWithKitSet &&
												this.state.kitWithKitSet
													.filter(
														(item) =>
															item.cartAdded != 0
													)
													.map((kit, index) => (
														<React.Fragment
															key={index}
														>
															<tr>
																<th colSpan="2">
																	<span className="mr-4 kitname">
																		{
																			kit.name
																		}
																		{kit
																			.kitDocument
																			.length >
																			0 && (
																			<span className="ml-1">
																				<a
																					id={
																						"UncontrolledTooltipPdf2" +
																						index
																					}
																					target="_blank"
																					rel="noreferrer"
																					className="download-info badge1 badge-info1"
																					href={
																						process
																							.env
																							.REACT_APP_APIURL +
																						"assets/uploads/kitDocuments/" +
																						kit
																							.kitDocument[0]
																							.name
																					}
																				>
																					Download
																					Tray
																					List
																				</a>
																			</span>
																		)}
																	</span>

																	<span
																		style={{
																			"vertical-align":
																				"bottom",
																		}}
																		className="small"
																	>
																		{(kit.hire_charge >
																			0 ||
																			kit.quantity >
																				0) && (
																			<React.Fragment>
																				<span>
																					Requested
																					Quantity
																					:{" "}
																					{
																						kit.quantity
																					}
																				</span>{" "}
																				&nbsp;
																				<span>
																					Hire
																					Charges
																					(Per
																					Set)
																					:{" "}
																					{
																						kit.hire_charge
																					}
																				</span>
																			</React.Fragment>
																		)}
																	</span>

																	<div className="mt-2">
																		{kit.sets
																			.filter(
																				(
																					item
																				) =>
																					item.added ==
																					1
																			)
																			.map(
																				(
																					setd,
																					ind
																				) => (
																					<span
																						className="d-inline-block capsul_kit"
																						key={
																							ind
																						}
																					>
																						{
																							setd.set_name
																						}{" "}
																					</span>
																				)
																			)}
																	</div>
																</th>
																<td className="text-right"></td>
															</tr>
															<KitModal
																isOpen={
																	this.state
																		.isOpen
																}
																toggle={
																	this
																		.closeModal
																}
																item={kit}
															/>
														</React.Fragment>
													))}
										</tbody>
									</table>
								</div>

								<div className="row align-items-end">
									<div className="col-12">
										<label>
											Select Kit{" "}
											<span className="text-danger">
												*
											</span>
										</label>
										<div className="d-flex justify-content-between align-items-center">
											<div
												className="form-group mb-0"
												style={{
													width: "calc(100% - 80px)",
												}}
											>
												<Select
													value={
														this.state.selectedKit
													}
													onChange={
														this
															.setSelectedOptionKit
													}
													options={this.state.kits}
													className="small"
												/>
											</div>
											<div>
												<a
													className="btn btn-primary small"
													href="javascript:;"
													onClick={() =>
														this.cartToggle()
													}
												>
													<i className="fa fa-shopping-cart"></i>{" "}
													{this.state.cartItemCount}
												</a>
											</div>
										</div>
										{this.state.kitSetErrorFlag == 1 ? (
											<span className="text-danger">
												Please add at least one kit set
											</span>
										) : (
											""
										)}
									</div>

									<div className="col-sm-12 mt-3">
										<div
											className={
												this.state.cartFlag === true
													? "collapse1 show1"
													: " collapse1 "
											}
											id="collapseExample"
										>
											<div className="card card-body">
												<table className="table mb-0 mobile_full">
													<tbody>
														{this.state
															.kitWithKitSet &&
															this.state.kitWithKitSet
																.filter(
																	(item) =>
																		item.cartAdded !=
																		0
																)
																.map(
																	(
																		kit,
																		index
																	) => (
																		<React.Fragment
																			key={
																				index
																			}
																		>
																			<tr>
																				<th
																					colSpan="2"
																					className="w-100"
																				>
																					<span className="mt-4">
																						{
																							kit.name
																						}
																						{kit
																							.kitDocument
																							.length >
																							0 && (
																							<span className="ml-1">
																								<a
																									id={
																										"UncontrolledTooltipPdf3" +
																										index
																									}
																									target="_blank"
																									rel="noreferrer"
																									className="download-info badge1 badge-info1"
																									href={
																										process
																											.env
																											.REACT_APP_APIURL +
																										"assets/uploads/kitDocuments/" +
																										kit
																											.kitDocument[0]
																											.name
																									}
																								>
																									Download
																									Tray
																									List
																								</a>
																							</span>
																						)}
																					</span>
																				</th>
																				<td className="text-right">
																					<a
																						href="javascript:;"
																						onClick={() =>
																							this.handelRemovKitFromCart(
																								index,
																								kit._id
																							)
																						}
																					>
																						<i className="fa fa-trash"></i>
																					</a>
																				</td>
																			</tr>
																			{this.checkAvalabilityForConnsignment(
																				kit
																			)}
																			{kit.sets.map(
																				(
																					setd,
																					ind
																				) => (
																					<tr
																						key={
																							ind
																						}
																					>
																						{this.checkAvalability(
																							setd.bookingKitSets,
																							setd,
																							setd._id,
																							index,
																							ind,
																							kit._id,
																							1
																						)}
																					</tr>
																				)
																			)}
																		</React.Fragment>
																	)
																)}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>

								<div className="mt-4">
									<table className="table">
										<tbody>
											{this.state.kitWithKitSet &&
												this.state.cartFlag == false &&
												this.state.kitWithKitSet
													.filter(
														(item) =>
															item._id ==
															this.state
																.singleSelectedKitId
													)
													.map((kit, index) => (
														<React.Fragment
															key={index}
														>
															<tr>
																<th colSpan="2">
																	<span
																		className="mr-4"
																		onClick={
																			this
																				.openModal
																		}
																	>
																		{
																			kit.name
																		}
																		{kit
																			.kitDocument
																			.length >
																			0 && (
																			<span className="ml-1">
																				<a
																					id={
																						"UncontrolledTooltipPdf4" +
																						index
																					}
																					target="_blank"
																					rel="noreferrer"
																					className="download-info badge1 badge-info1"
																					href={
																						process
																							.env
																							.REACT_APP_APIURL +
																						"assets/uploads/kitDocuments/" +
																						kit
																							.kitDocument[0]
																							.name
																					}
																				>
																					Download
																					Tray
																					List
																				</a>
																			</span>
																		)}
																	</span>

																	<span
																		style={{
																			"vertical-align":
																				"bottom",
																		}}
																		className="small"
																	>
																		{(kit.hire_charge >
																			0 ||
																			kit.quantity >
																				0) && (
																			<React.Fragment>
																				<span>
																					Requested
																					Quantity
																					:{" "}
																					{
																						kit.quantity
																					}
																				</span>{" "}
																				&nbsp;
																				<span>
																					Hire
																					Charges
																					(Per
																					Set)
																					:{" "}
																					{
																						kit.hire_charge
																					}
																				</span>
																			</React.Fragment>
																		)}
																	</span>
																</th>
																<td className="text-right">
																	<a
																		href="javascript:;"
																		onClick={() =>
																			this.handelRemovKit(
																				index,
																				kit._id
																			)
																		}
																	>
																		{" "}
																		<i className="fa fa-trash"></i>
																	</a>
																</td>
															</tr>
															{this.checkAvalabilityForConnsignment(
																kit,
																index
															)}
															{kit.sets.map(
																(setd, ind) => (
																	<tr
																		key={
																			ind
																		}
																	>
																		{this.checkAvalability(
																			setd.bookingKitSets,
																			setd,
																			setd._id,
																			index,
																			ind,
																			kit._id,
																			2
																		)}
																	</tr>
																)
															)}
															<KitModal
																isOpen={
																	this.state
																		.isOpen
																}
																toggle={
																	this
																		.closeModal
																}
																item={kit}
															/>
														</React.Fragment>
													))}
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div className="col-lg-4">
							<div className="d-card">
								<h4>Charges</h4>
								<div className="form-group">
									<label>Shipping Charge</label>
									<input
										type="number"
										min="0"
										className="form-control"
										value={
											this.state.updateBookingInfo
												.shipping_charge
										}
										onChange={(e) =>
											this.handleUpdateBookingInfo(
												"shipping_charge",
												e.target.value
											)
										}
									/>
								</div>
							</div>

							<div className="d-card mt-4">
								<h4>Booked By</h4>
								<div className="form-group">
									<label>Assigned Rep</label>
									<div className="position_up">
										<Select
											value={this.state.selectedRep}
											onChange={this.setSelectedOptionRep}
											options={this.state.reps}
										/>
										{error ? (
											<span className="text-danger">
												{error.assigned_rep
													? error.assigned_rep
													: ""}
											</span>
										) : (
											""
										)}
									</div>
								</div>
								<div className="form-group">
									<label>Additional Reps</label>
									<Select
										value={this.state.selectedAdditionalRep}
										onChange={
											this.setSelectedOptionAdditionalRep
										}
										options={this.state.additionalReps}
										isMulti={true}
									/>
								</div>
							</div>

							<div className="d-card mt-4">
								<h4>Additional Notes</h4>
								<div className="form-group">
									<textarea
										className="form-control"
										rows="4"
										value={
											this.state.updateBookingInfo
												.additional_notes
										}
										onChange={(e) =>
											this.handleUpdateBookingInfo(
												"additional_notes",
												e.target.value
											)
										}
									></textarea>
								</div>
							</div>

							<div className="mt-5 row">
								{isLoading == false && (
									<React.Fragment>
										<div className="col-6">
											<Link
												to="/admin/bookings"
												className="btn btn-block btn-secondary"
											>
												Cancel
											</Link>
										</div>
										<div className="col-6">
											<a
												href="javascript:;"
												onClick={() =>
													this.saveChangeHandle(true)
												}
												className="btn btn-block btn-primary"
												// disabled={!changesMade}
											>
												Save Changes
											</a>
										</div>
										<div className="col-12 mt-3">
											<a
												href="javascript:;"
												onClick={() =>
													this.sendEmailConfirmHandle(
														true
													)
												}
												className="btn btn-block btn-primary"
												// disabled={!changesMade}
											>
												Save and send email
											</a>
										</div>
									</React.Fragment>
								)}
								{isLoading == true && (
									<React.Fragment>
										<div className="col-6">
											<Link
												to="/admin/bookings"
												className="btn btn-secondary btn-block"
											>
												Cancel
											</Link>
										</div>
										<div className="col-6">
											<button
												className="btn btn-primary loader-icon btn-block"
												disabled
											>
												<Spinner className="img-svg"></Spinner>
											</button>
										</div>
									</React.Fragment>
								)}
							</div>
						</div>
					</div>

					{/* ADD FORM */}
				</div>
				<Modal
					size="lg"
					backdrop="static"
					isOpen={this.state.kitSetDetailModal}
					toggle={() => this.kitSetDetailModalClose()}
				>
					<ModalHeader
						className=""
						toggle={() => this.kitSetDetailModalClose()}
					>
						Set Details
					</ModalHeader>
					<ModalBody>
						<table className="table">
							<thead>
								<tr>
									<th>Item Code</th>
									<th>Item Name</th>
									<th>Qty</th>
								</tr>
							</thead>
							<tbody>
								{this.state.kitDetails?.setProductModules &&
									this.state.kitDetails?.setProductModules.map(
										(product, ind) => (
											<tr key={ind}>
												<td>
													{product.productID.code}
												</td>
												<td>
													{product.productID.name}
												</td>
												<td>{product.quantity}</td>
											</tr>
										)
									)}
							</tbody>
						</table>
					</ModalBody>
					<ModalFooter>
						<div className="">
							<button
								className="btn btn-secondary"
								onClick={() => this.kitSetDetailModalClose()}
							>
								Close
							</button>
						</div>
					</ModalFooter>
				</Modal>
				{/* Set Details Modal */}
				<AddHospital
					addNewModalClose={this.addNewHospitalModalClose.bind(this)}
					addNewModal={this.state.addNewHospitalModalProp}
				/>
				<UpdateHospital
					editModalClose={this.editHospitalModalClose.bind(this)}
					editModal={this.state.editHospitalModal}
					editId={this.state.editHospitalId}
					refreshGetList={this.refreshHospitalList.bind(this)}
				/>
				<AddSurgeon
					addNewModalClose={this.addNewSurgeonModalClose.bind(this)}
					addNewModalProp={this.state.addSurgeonModal}
				/>
				<SameDateModal
					show={this.state.sameDatesModal}
					sameDatesInfo={this.state.updateBookingInfo}
					saveChangeHandle={(flag) => {
						this.saveChangeHandle(flag);
					}}
					onClose={() =>
						this.setState({
							sameDatesModal: false,
							checkDateFlag: false,
							sameDatesInfo: [],
						})
					}
					onSubmit={this.handleSubmit}
					checkDateFlag={this.state.checkDateFlag}
					checkDateModalClose={this.checkDateModalClose?.bind(this)}
				/>
				<WarningModal
					addedKitFlag={this.state.addedKitFlag}
					warningModalClose={this.warningModalClose.bind(this)}
				/>
				;
				<DoubleBookingDetails
					doubleBookingDetailModal={
						this.state.doubleBookingDetailModal
					}
					doubleBookingDetailModalClose={this.doubleBookingDetailModalClose.bind(
						this
					)}
					doubleBookingInfo={this.state.doubleBookingInfo}
					saveChangeHandle={(flag) => this.saveChangeHandle(flag)}
				/>
				<Footer />
			</div>
		);
	}
}

function mapStateToProps(state) {
	const {
		bookingInfo,
		hospitalDetails,
		hospitalsList,
		repsList,
		error,
		redirect,
		kitSetList,
		loading,
		doubleBookingList,
	} = state.rootReducer.bookings;
	const { kitListDropdown } = state.rootReducer.kits;
	const { editSet, set } = state.rootReducer.sets;

	const { opLists } = state.rootReducer.operations;

	return {
		hospitalsList,
		bookingInfo,
		hospitalDetails,
		repsList,
		error,
		redirect,
		kitListDropdown,
		kitSetList,
		editSet,
		set,
		loading,
		opLists,
		doubleBookingList,
	};
}

export default connect(mapStateToProps)(Edit);
