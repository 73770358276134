import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function QuarantineModal({ isOpen, onClose, onSubmit }) {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [comment, setComment] = useState("");

	const handleSubmit = () => {
		onSubmit(selectedDate, comment);
		onClose();
		setSelectedDate(new Date());
		setComment("");
	};

	return (
		<Modal centered size="md" backdrop="static" isOpen={isOpen} toggle={onClose}>
			<ModalHeader className="" toggle={onClose}>
				Quarantine Modal
			</ModalHeader>
			<ModalBody>
				<div className="form-group">
					<label>Date</label>
					<DatePicker
						selected={selectedDate}
						onChange={(date) => setSelectedDate(date)}
						className="form-control"
						dateFormat="dd/MM/yyyy"
					/>
				</div>
				<div className="form-group">
					<label>Comment</label>
					<textarea
						className="form-control"
						rows="2"
						value={comment}
						onChange={(e) => setComment(e.target.value)}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<div className="">
					<button
						onClick={onClose}
						className="btn btn-secondary mr-2"
						data-dismiss="modal"
					>
						Cancel
					</button>
					<button className="btn btn-primary" onClick={handleSubmit}>
						Ok
					</button>
				</div>
			</ModalFooter>
		</Modal>
	);
}

export default QuarantineModal;
