import React, { Component } from "react";
import { connect } from "react-redux";
import Footer from "../../../GlobalComponents/Footer";
import Navbar from "../Navbar";
import KitNavbar from "./KitNavbar";
import { Link, Redirect } from "react-router-dom";
import Pagination from "react-js-pagination";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { setActions } from "../../../../actions";
import { ReactComponent as Spinner } from "../../../../assets/images/Spinner-1s-200px.svg";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setService } from "../../../../services";
import QuarantineModal from "./Modal/quarantineModal";
import MissingStockModal from "./Modal/missingStockModal";
import dateFormat from "dateformat";
import * as XLSX from "xlsx";

const downloadExcel = (data) => {
  const transformedData = data.map((item) => ({
    "Set ID": item.set_id,
    "Missing Stock Check": item.missing_stock_check ? "Yes" : "No",
    "Quarantine Check": item.quarantine_check ? "Yes" : "No",
    "Missing Stock Check Date": item.missing_stock_check_date
      ? dateFormat(item.missing_stock_check_date, "fullDate")
      : "",
    "Quarantine Check Date": item.quarantine_check_date
      ? dateFormat(item.quarantine_check_date, "fullDate")
      : "",
    "Missing Stock Comment": item.missing_stock_comment,
    "Quarantine Check Comment": item.quarantine_check_comment,
  }));

  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(transformedData);
  const headerCell = Object.keys(transformedData[0]);
  ws["!cols"] = [
    { wpx: 150 },
    { wpx: 180 },
    { wpx: 180 },
    { wpx: 180 },
    { wpx: 180 },
    { wpx: 250 },
    { wpx: 250 },
  ];

  headerCell.forEach((key, index) => {
    const cellAddress = XLSX.utils.encode_cell({ c: index, r: 0 });
    ws[cellAddress].s = {
      font: { bold: true },
    };
  });

  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  XLSX.writeFile(wb, "Sets History.xlsx");
};

const statusOptions = [
  { value: "all", label: "All" },
  { value: "missing_stock", label: "Missing Stocks" },
  { value: "quarantine", label: "Quarantine" },
];

const visibilityOptions = [
  { value: "Active", label: "Active" },
  { value: "Deactive", label: "Deactive" },
];
class Kits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(localStorage.getItem("user")),
      deleteModal: false,
      id: "",
      search: "",
      activePage: 1,
      limit: 50,
      totalItemsCount: 10,
      status: "all",
      visibility: "Active",
      selectedFilter: { value: "all", label: "All" },
      selectedStatus: { value: "Active", label: "Active" },
      quarantine_check: false,
      missing_stock_check: false,
      openQuarantineModal: false,
      openMissingStockModal: false,
      missing_stock_date: new Date(),
      missing_stock_comment: "",
      quarantine_date: new Date(),
      quarantine_comment: "",
      counter: 0,
      totalCounter: 0,
      resetDates: "",
      dataItem: [],
    };

    this.handlePageChange = this.handlePageChange.bind(this);
    this.deleteModalOpen = this.deleteModalOpen.bind(this);
    this.deleteModalClose = this.deleteModalClose.bind(this);
    this.QuarantineModalOpen = this.QuarantineModalOpen.bind(this);
    this.handleQuarantineCheck = this.handleQuarantineCheck.bind(this);
    this.handleMissingStockCheck = this.handleMissingStockCheck.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.doDelete = this.doDelete.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.setMissingStock = this.setMissingStock.bind(this);
    this.setQuarantine = this.setQuarantine.bind(this);
    this.handleVisiblity = this.handleVisiblity.bind(this);
    this.updateVisibility = this.updateVisibility.bind(this);

    this.QuarantineModalClose = this.QuarantineModalClose.bind(this);
    this.MissingStockModalClose = this.MissingStockModalClose.bind(this);
    this.handleMissingStockSubmit = this.handleMissingStockSubmit.bind(this);
    this.handleQuarantineSubmit = this.handleQuarantineSubmit.bind(this);
    this.resetCounter = this.resetCounter.bind(this);
  }

  handlePageChange(pageNumber) {
    if (pageNumber !== this.state.activePage) {
      this.setState(
        {
          activePage: pageNumber,
        },
        () => {
          this.getSetsList();
        }
      );
      window.scrollTo({ top: 0 });
    }
  }

  handleVisiblity(e) {
    this.setState(
      {
        visibility: e.value,
        selectedStatus: e,
      },
      () => {
        this.getSetsList();
      }
    );
  }

  updateVisibility(id, status) {
    console.log("updateVisibility", id, status);
    setService
      .updateSetStatus({ status: status, id: id })
      .then(() => this.getSetsList());
  }

  handlePageStates(activePage, totalItemsCount, limit) {
    this.setState({
      activePage: activePage,
      totalItemsCount: totalItemsCount,
      limit: limit,
    });
  }

  getSetsList() {
    this.props.dispatch(
      setActions.getSetsList({
        search: this.state.search,
        page: this.state.activePage,
        limit: this.state.limit,
        filter: this.state.status,
        status: this.state.visibility,
      })
    );
  }

  handleSearch(val) {
    this.setState(
      {
        search: val,
        activePage: 1,
      },
      () => {
        this.getSetsList();
      }
    );
  }

  deleteModalOpen() {
    this.setState({ deleteModal: true });
  }

  deleteModalClose() {
    this.setState({
      deleteModal: false,
      id: "",
    });
  }

  QuarantineModalOpen() {
    this.setState({ openQuarantineModal: true });
  }

  QuarantineModalClose() {
    this.setState({
      openQuarantineModal: false,
      id: "",
    });
  }

  handleOpenQuarantineModal(e, id) {
    e.preventDefault();
    this.setState({
      id: id,
      error: false,
    });
    this.QuarantineModalOpen();
  }

  MissingStockModalOpen() {
    this.setState({ openMissingStockModal: true });
  }

  MissingStockModalClose() {
    this.setState({
      openMissingStockModal: false,
    });
  }

  handleOpenMissingStockModal(e, id) {
    e.preventDefault();
    this.setState({
      id: id,
      error: false,
    });
    this.MissingStockModalOpen();
  }

  handleDelete(e, id) {
    e.preventDefault();
    this.setState({
      id: id,
      error: false,
    });
    this.deleteModalOpen();
  }

  handleDownloadHistory(e, id) {
    e.preventDefault();
    console.log("download History api", id);
    let data = [];
    setService
      .getSetHistory({ id: id })
      .then((res) => {
        if (res.status === 200) {
          this.getSetsList();
          downloadExcel(res.data);
        }
        console.log("ayushi yay", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    console.log("daata", data);
  }

  doDelete() {
    this.setState({
      error: true,
    });
    this.props.dispatch(setActions.delete({ id: this.state.id }));
  }

  handleQuarantineCheck(event, item) {
    const checked = event.target.checked;
    console.log("item", item);
    this.setState({
      dataItem: item,
      id: item._id,
      quarantine_check: checked,
    });

    if (checked) {
      this.setState({
        openQuarantineModal: true,
      });
    } else {
      this.setQuarantine(false, item);
    }
  }

  handleMissingStockCheck(event, item) {
    const checked = event.target.checked;

    this.setState({
      dataItem: item,
      id: item._id,
      missing_stock_check: checked,
    });

    if (checked) {
      this.setState({
        openMissingStockModal: true,
      });
    } else {
      this.setMissingStock(false, item);
    }
  }

  handleMissingStockSubmit(date, comment) {
    console.log("Missing stock Selected Date:", date);
    console.log("Missing stock Comment:", comment);
    console.log("Missing stock ID:", this.state.id);
    this.setState(
      {
        missing_stock_check: true,
        missing_stock_date: date,
        missing_stock_comment: comment,
        openMissingStockModal: false,
      },
      () => {
        this.setMissingStock(true, this.state.dataItem);
      }
    );
  }

  setMissingStock(val, item) {
    this.setState({
      missing_stock_check: val,
    });
    const payloadMisstock = {
      dataItem: item,
      id: item._id,
      missing_stock_check: val,
      missing_stock_comment: this.state.missing_stock_comment,
      missing_stock_check_date: this.state.missing_stock_date,
      quarantine_check: item.quarantine_check ? true : false,
    };

    setService
      .updateMissingAndQuarantineStock(payloadMisstock)
      .then((res) => {
        if (res.status === 200) {
          this.getSetsList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setQuarantine(val, item) {
    console.log("ajajaj", item);
    this.setState({
      quarantine_check: val,
    });
    const payloadQuarantine = {
      id: item._id,
      quarantine_check: val,
      quarantine_check_comment: this.state.quarantine_comment,
      quarantine_check_date: this.state.quarantine_date,
      missing_stock_check: item.missing_stock_check ? true : false,
    };
    console.log("payloadQuarantine", payloadQuarantine);
    setService
      .updateMissingAndQuarantineStock(payloadQuarantine)
      .then((res) => {
        if (res.status === 200) {
          this.getSetsList();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log(nextProps);

    if (nextProps.refreshList == true || nextProps.redirect == true) {
      this.setState(
        {
          activePage: this.state.activePage,
        },
        () => {
          this.getSetsList();
        }
      );
    }

    if (
      nextProps.activePage != "" &&
      nextProps.totalItemsCount != "" &&
      nextProps.limit != ""
    ) {
      this.handlePageStates(
        nextProps.activePage,
        nextProps.totalItemsCount,
        this.state.limit
      );
    }

    if (nextProps.deleteModal == false) {
      this.deleteModalClose();
    }

    if (nextProps.error !== "") {
      if (nextProps.error !== undefined) {
        if (nextProps.error.description) {
          toast.error(nextProps.error.description[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }

        if (nextProps.error.kitID) {
          toast.error(nextProps.error.kitID[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }

        if (nextProps.error.set_name) {
          toast.error(nextProps.error.set_name[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    }
  }

  handleStatus(e) {
    this.setState(
      {
        status: e.value,
        selectedFilter: e,
        activePage: 1,
      },
      () => {
        this.getSetsList();
      }
    );
  }

  componentDidMount() {
    window.scrollTo({ top: 0 });
    this.getSetsList();
  }

  handleQuarantineSubmit(date, comment, item) {
    console.log("llll", item);
    const { dataItem } = this.state;

    this.setState(
      {
        quarantine_check: true,
        quarantine_date: date,
        quarantine_comment: comment,
        openQuarantineModal: false,
      },
      () => {
        this.setQuarantine(true, dataItem);
      }
    );
  }

  handleCounter(val) {
    this.setState({
      counter: val,
    });
  }

  resetCounter(id) {
    console.log("zzzz", id);
    this.setState({
      counter: 0,
    });
    setService.resetCounter({ setId: id }).then(() => this.getSetsList());
  }

  render() {
    if (!this.state.user) {
      return <Redirect to="/" />;
    }
    if (this.state.user.data.user_role !== "admin") {
      return <Redirect to="/" />;
    }
    const { setsList, loading, error } = this.props;
    return (
      <div>
        <ToastContainer />
        <Navbar activePage="kits" />
        <div className="container-fluid">
          <div className="pt-4 row align-items-center">
            <div className="col-md-3">
              <div className="sub-menu">
                <KitNavbar activePage="sets" />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-search">
                <Input
                  className="form-control mr-sm-2"
                  type="text"
                  placeholder="Search.."
                  value={this.state.search}
                  onChange={(e) => this.handleSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-2">
              <form className="form-search my-1">
                <Select
                  defaultValue={this.state.selectedFilter}
                  onChange={this.handleStatus}
                  options={statusOptions}
                />
              </form>
            </div>
            <div className="col-md-2">
              <form className="form-search my-1">
                <Select
                  defaultValue={this.state.selectedStatus}
                  onChange={this.handleVisiblity}
                  options={visibilityOptions}
                />
              </form>
            </div>
            <div className="col-md-2 text-right">
              <Link to="/admin/sets" className="btn btn-primary add_option">
                <i className="fa fa-plus"></i> <span>Add New</span>
              </Link>
            </div>
          </div>
          <div id="table-scroll" className="table-scroll mt-4">
            <table id="main-table" className="main-table">
              <thead>
                <tr>
                  <th scope="col">Set Name</th>
                  <th scope="col" className="" style={{ minWidth: "120px" }}>
                    Kit
                  </th>
                  <th scope="col" style={{ width: "90px" }}>
                    Counter
                  </th>
                  <th scope="col">Date of Last Reset</th>
                  <th scope="col" style={{ width: "90px" }}>
                    Total Counter
                  </th>
                  <th scope="col">Missing Stock</th>
                  {/* <th scope="col">Missing Stock Comment</th> */}
                  <th scope="col">Quarantine</th>
                  {/* <th scope="col">Date</th> */}
                  <th scope="col"  style={{ width: "200px" }} >Missing Stock (Date/Comment)</th>
                  <th scope="col" style={{ width: "200px" }}>Quarantine (Date/Comment)</th>
                  <th scope="col">Name</th>
                  <th scope="col"></th>
                  <th scope="col">Status</th>
                  <th scope="col" className="text-right" width="100">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {setsList &&
                  setsList.map((set) => {
                    console.log("ayushi", set);
                    return (
                      <tr key={set.sets._id}>
                        <td>
                          <div className="">
                            <span>
                              <a href="#">
                                <strong>{set.sets.set_name}</strong>
                              </a>
                            </span>
                          </div>
                        </td>
                        <td className="">{set.sets.kitID[0].name}</td>
                        <td>
                          <Input  
                            type="number"
                            value={set.sets.counter ? set.sets.counter : 0}
                            onChange={(val) => this.handleCounter(val)}
                            className="form-control"
                            readOnly
                            style={{ width: "90%", backgroundColor: "#fff" }}
                          />
                          <p
                            style={{
                              fontSize: "13px",
                              textAlign: "center",
                              marginTop: "5px",
                              cursor: "pointer",
                              color: "blue",
                              marginLeft: "20px",
                            }}
                            onClick={() => this.resetCounter(set.sets._id)}
                          >
                            Reset
                          </p>
                        </td>
                        <td>
                          {set.sets.lastResetDate
                            ? dateFormat(set.sets.lastResetDate, "mmm dS, yyyy")
                            : ""}
                        </td>
                        <td>
                          <Input
                            type="number"
                            value={
                              set.sets.totalCounter ? set.sets.totalCounter : 0
                            }
                            className="form-control"
                            readOnly
                            style={{ width: "90%", backgroundColor: "#fff" }}
                          />
                        </td>

                        <td style={{ minHeight: "44px" }}>
                          <Input
                            className="form-check-input mx-1"
                            type="checkbox"
                            value={
                              set?.sets?.missing_stock_check ? true : false
                            }
                            checked={set?.sets?.missing_stock_check || false}
                            onChange={(e) =>
                              this.handleMissingStockCheck(e, set.sets)
                            }
                          />
                        </td>
                        {/* <td>{set.sets.missing_stock_comment}</td> */}
                        <td style={{ minHeight: "44px" }}>
                          <Input
                            className="form-check-input mx-1"
                            type="checkbox"
                            checked={set?.sets?.quarantine_check || false}
                            value={set?.sets?.quarantine_check ? true : false}
                            onChange={(e) =>
                              this.handleQuarantineCheck(e, set.sets)
                            }
                          />
                        </td>
                        <td>
                        <strong>Date:</strong> <br/>
                        <span >{ dateFormat(new Date(), "isoDate")}</span>
                        <br/>   <br/>
                        <strong>Comment:</strong><br/>
                        this is missing 
                       
                          {/* {set.sets.missing_stock_comment}
                          <br /> <br/>
                          <strong>Quarantine:</strong> <br/>
                          { dateFormat(new Date(), "isoDate")}
                          {set.sets.quarantine_check_comment} */}
                        </td>

                        <td>
                        <strong>Date:</strong> <br/>
                        <span >{ dateFormat(new Date(), "isoDate")}</span>
                        <br/>   <br/>
                        <strong>Comment:</strong><br/>
                        this is missing this is missing this is missing this is missin
                          {/* <strong>Missing Stock:</strong><br/>
                          this is missing this is missing this is missing this is missin
                          {set.sets.missing_stock_comment}
                          <br /> <br/>
                          <strong>Quarantine:</strong><br/>
                          this is missing this is missing this is missing this is missing
                          {set.sets.quarantine_check_comment} */}
                        </td>
                        <td>Max Smith</td>
                        <td style={{ minHeight: "44px" }}></td>
                        <td>
                          {set?.sets?.status === "Active"
                            ? "Active"
                            : "Deactive"}
                        </td>
                        <td className="text-right">
                          <div className="action-area dropdown">
                            <a
                              className="dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <span className="dots">...</span>
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              {set?.sets?.status == "Active" ? (
                                <Link
                                  className="dropdown-item"
                                  to={"/admin/edit-sets/" + set.sets._id}
                                >
                                  Edit
                                </Link>
                              ) : (
                                ""
                              )}
                              {set?.sets?.status == "Active" ? (
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    this.updateVisibility(
                                      set.sets._id,
                                      "Deactive"
                                    )
                                  }
                                  href="javascript:;"
                                >
                                  Deactive
                                </a>
                              ) : (
                                <a
                                  className="dropdown-item"
                                  onClick={() =>
                                    this.updateVisibility(
                                      set.sets._id,
                                      "Active"
                                    )
                                  }
                                  href="javascript:;"
                                >
                                  Active
                                </a>
                              )}
                              <a
                                className="dropdown-item"
                                onClick={(e) =>
                                  this.handleDelete(e, set.sets._id)
                                }
                                href="#"
                              >
                                Delete
                              </a>
                              <Link
                                className="dropdown-item"
                                onClick={(e) =>
                                  this.handleDownloadHistory(e, set.sets._id)
                                }
                              >
                                Download History
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                {setsList && setsList.length == 0 && loading == false && (
                  <tr className="text-center">
                    <td colSpan="5">No Record Found</td>
                  </tr>
                )}

                {setsList && setsList.length == 0 && loading == true && (
                  <tr className="text-center">
                    <td colSpan="5">
                      <div className="loading-state-new">
                        <div className="loading"></div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {setsList && setsList.length > 0 && (
          <div>
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.limit}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={5}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
              innerClass="pagination justify-content-center mb-5"
              activeLinkClass="active"
              nextPageText="Next"
              prevPageText="Prev"
            />
          </div>
        )}

        <QuarantineModal
          isOpen={this.state.openQuarantineModal}
          onClose={this.QuarantineModalClose}
          onSubmit={this.handleQuarantineSubmit}
        />

        <MissingStockModal
          isOpen={this.state.openMissingStockModal}
          onClose={this.MissingStockModalClose}
          onSubmit={this.handleMissingStockSubmit}
        />

        <Modal
          size="md"
          backdrop="static"
          isOpen={this.state.deleteModal}
          toggle={() => this.deleteModalClose()}
        >
          <ModalHeader className="" toggle={() => this.deleteModalClose()}>
            Delete Set
          </ModalHeader>
          <ModalBody>
            <div className="my-2">
              Please confirm if you want to delete this set?
            </div>
            {this.state.error && error ? (
              <span className="text-danger">
                {error.common ? error.common : ""}
              </span>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <div className="">
              <button
                onClick={() => this.deleteModalClose()}
                className="btn btn-secondary mr-2"
                data-dismiss="modal"
              >
                Cancel
              </button>
              {loading == false && (
                <button className="btn btn-primary" onClick={this.doDelete}>
                  Ok
                </button>
              )}
              {loading == true && (
                <button className="btn btn-primary loader-icon" disabled>
                  <Spinner className="img-svg"></Spinner>
                </button>
              )}
            </div>
          </ModalFooter>
        </Modal>

        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { setsList, loading, deleteModal, refreshList, error } =
    state.rootReducer.sets;
  const { activePage, totalItemsCount, limit, redirect } =
    state.rootReducer.sets;

  return {
    setsList,
    loading,
    deleteModal,
    refreshList,
    activePage,
    totalItemsCount,
    limit,
    error,
    redirect,
  };
}

export default connect(mapStateToProps)(Kits);
